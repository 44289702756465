import * as Sentry from '@sentry/react';
import { BrowserTracing } from '@sentry/tracing';
import ReactDOM from 'react-dom/client';
import { SENTRY_DSN } from 'shared/constants';
import Root from './Root';
import * as serviceWorker from './serviceWorker';

const rootEl = document.getElementById('root') as Element;

if (['production', 'staging', 'development', 'local'].includes(process.env.REACT_APP_ENVIRONMENT as string)) {
	// Create a reusable render method that we can call more than once
	Sentry.init({
		dsn: SENTRY_DSN,
		integrations: [new BrowserTracing()],

		// Set tracesSampleRate to 1.0 to capture 100%
		// of transactions for performance monitoring.
		// We recommend adjusting this value in production
		tracesSampleRate: 1.0
	});
}

const root = ReactDOM.createRoot(rootEl);
root.render(<Root />);

serviceWorker.unregister();
