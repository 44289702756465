import React from 'react';
import * as d3 from 'd3';
import { useLocation } from 'react-router-dom';
import { XAxis, YAxis } from 'react-stockcharts/lib/axes';
import { MouseCoordinateY } from 'react-stockcharts/lib/coordinates';
import { barChartTickFormat } from 'shared/util/utility';

import { mouseEdgeAppearance } from 'shared/constants/constants';
import { IChartDesignConfig } from '../interface/futureTable';

interface IProps {
	chartType: string;
	span: string;
	data: any;
	axisAt: string;
	chartDesignConfig: IChartDesignConfig;
	xTicks: number;
	yTicks: number;
	tickFormat: (d: number) => void;
	isCompareWithSelfEnabled: boolean;
}

const ChartAxis: React.FC<IProps> = (props) => {
	const { chartType, span, data, axisAt, chartDesignConfig, xTicks, yTicks, isCompareWithSelfEnabled, tickFormat } = props;
	const location = useLocation();
	const isIframe = location.pathname.includes('iframe');
	return (
		<>
			<YAxis
				axisAt={axisAt}
				orient={axisAt}
				ticks={yTicks}
				tickFormat={tickFormat}
				zoomEnabled={!isIframe}
				fontSize={isIframe ? 8 : chartDesignConfig.fontSize}
			/>
			<ReturnXAxis
				chartType={chartType}
				isCompareWithSelfEnabled={isCompareWithSelfEnabled}
				span={span}
				xTicks={xTicks}
				data={data}
				chartDesignConfig={chartDesignConfig}
			/>
			{!isIframe && (
				<g className={`${axisAt === 'right' ? 'right' : 'left'}`}>
					<MouseCoordinateY
						at={axisAt}
						orient={axisAt}
						fontSize={chartDesignConfig.fontSize}
						{...mouseEdgeAppearance}
						displayFormat={tickFormat}
						rectWidth={chartDesignConfig.rectWidth}
						rectHeight={chartDesignConfig.rectHeight}
					/>
				</g>
			)}
		</>
	);
};

export default ChartAxis;

interface IChartXaxisProps {
	span: string;
	chartType: string;
	isCompareWithSelfEnabled: boolean;
	xTicks: number;
	data: any;
	chartDesignConfig: IChartDesignConfig;
}

const ReturnXAxis: React.FC<IChartXaxisProps> = ({ span, chartType, isCompareWithSelfEnabled, xTicks, data, chartDesignConfig }) => {
	const location = useLocation();
	const isIframe = location.pathname.includes('iframe');
	if (chartType === 'bar' && (span === '1y' || span === '1w')) {
		return (
			<XAxis
				axisAt='bottom'
				orient='bottom'
				ticks={xTicks}
				fontSize={isIframe ? 8 : chartDesignConfig.fontSize}
				zoomEnabled={false}
				tickFormat={(d: number) => barChartTickFormat(d, data, span)}
			/>
		);
	} else if (isCompareWithSelfEnabled) {
		return (
			<XAxis
				axisAt='bottom'
				orient='bottom'
				ticks={xTicks}
				zoomEnabled={!isIframe}
				tickFormat={(d: number) => {
					const date = new Date(data[d].date);
					const formattedDate = d3.timeFormat('%d %b')(date);
					return formattedDate;
				}}
				fontSize={isIframe ? 8 : chartDesignConfig.fontSize}
			/>
		);
	} else {
		return <XAxis axisAt='bottom' orient='bottom' ticks={xTicks} zoomEnabled={!isIframe} fontSize={isIframe ? 8 : chartDesignConfig.fontSize} />;
	}
};
