import { extent as d3Extent, max, min } from 'd3-array';
import PropTypes from 'prop-types';
import { Component } from 'react';

import { clearCanvas, functor, getLogger, head, identity, isDefined, isNotDefined, last, noop, shallowEqual } from 'react-stockcharts/lib/utils';

import { isObject, mapObject, zipper } from 'react-stockcharts/lib/utils/index';

import { mouseBasedZoomAnchor } from 'react-stockcharts/lib/utils/zoomBehavior';

import { getCurrentCharts, getCurrentItem, getNewChartConfig } from 'react-stockcharts/lib/utils/ChartDataUtil';

import EventCapture from 'react-stockcharts/lib/EventCapture';

import { extent } from 'd3-array';
import { flattenDeep } from 'lodash';
import CanvasContainer from 'react-stockcharts/lib/CanvasContainer';
import evaluator from 'react-stockcharts/lib/scale/evaluator';
import { ScaleType } from 'shared/interface/enums';
const log = getLogger('ChartCanvas');

const CANDIDATES_FOR_RESET = ['seriesName', 'padding'];

function shouldResetChart(thisProps: any, nextProps: any) {
	return !CANDIDATES_FOR_RESET.every((key) => {
		const result = shallowEqual(thisProps[key], nextProps[key]);
		return result;
	});
}

function getCursorStyle() {
	const tooltipStyle = `
	.react-stockcharts-grabbing-cursor {
		pointer-events: all;
		cursor: -moz-grabbing;
		cursor: -webkit-grabbing;
		cursor: grabbing;
	}
	.react-stockcharts-crosshair-cursor {
		pointer-events: all;
		cursor: crosshair;
	}
	.react-stockcharts-tooltip-hover {
		pointer-events: all;
		cursor: pointer;
	}
	.react-stockcharts-avoid-interaction {
		pointer-events: none;
	}
	.react-stockcharts-enable-interaction {
		pointer-events: all;
	}
	.react-stockcharts-tooltip {
		pointer-events: all;
		cursor: pointer;
	}
	.react-stockcharts-default-cursor {
		cursor: default;
	}
	.react-stockcharts-move-cursor {
		cursor: move;
	}
	.react-stockcharts-pointer-cursor {
		cursor: pointer;
	}
	.react-stockcharts-ns-resize-cursor {
		cursor: ns-resize;
	}
	.react-stockcharts-ew-resize-cursor {
		cursor: ew-resize;
	}`;
	return <style type='text/css'>{tooltipStyle}</style>;
}

function getDimensions(props: any) {
	return {
		height: props.height - props.margin.top - props.margin.bottom,
		width: props.width - props.margin.left - props.margin.right
	};
}

function getXScaleDirection(flipXScale: any) {
	return flipXScale ? -1 : 1;
}

function calculateFullData(props: any) {
	const { data: fullData, plotFull, xScale, clamp, pointsPerPxThreshold, flipXScale } = props;
	const { xAccessor, displayXAccessor, minPointsPerPxThreshold } = props;

	const useWholeData = isDefined(plotFull) ? plotFull : xAccessor === identity;

	const { filterData } = evaluator({
		xScale,
		useWholeData,
		clamp,
		pointsPerPxThreshold,
		minPointsPerPxThreshold,
		flipXScale
	});

	return {
		xAccessor,
		displayXAccessor: displayXAccessor || xAccessor,
		xScale: xScale.copy(),
		fullData,
		filterData
	};
}
function resetChart(props: any, firstCalculation = false, chartView: string) {
	if (process.env.NODE_ENV !== 'production') {
		if (!firstCalculation) log('CHART RESET');
	}

	const state = calculateState(props);
	const { xAccessor, displayXAccessor, fullData } = state;
	const { plotData: initialPlotData, xScale } = state;
	const { postCalculator, children } = props;

	const plotData = postCalculator(initialPlotData);

	const dimensions = getDimensions(props);
	const chartConfig = getChartConfigWithUpdatedYScales(
		getNewChartConfig(dimensions, children),
		{ plotData, xAccessor, displayXAccessor, fullData },
		xScale.domain(),
		chartView
	);
	return {
		...state,
		xScale,
		plotData,
		chartConfig
	};
}

function updateChart(newState: any, initialXScale: any, props: any, lastItemWasVisible: any, initialChartConfig: any, chartView: string) {
	const { fullData, xScale, xAccessor, displayXAccessor, filterData } = newState;

	const lastItem = last(fullData);
	const [start, end] = initialXScale.domain();

	if (process.env.NODE_ENV !== 'production') {
		log('TRIVIAL CHANGE');
	}

	const { postCalculator, children, padding, flipXScale } = props;
	const { maintainPointsPerPixelOnResize } = props;
	const direction = getXScaleDirection(flipXScale);
	const dimensions = getDimensions(props);

	const updatedXScale = setXRange(xScale, dimensions, padding, direction);

	let initialPlotData;
	if (!lastItemWasVisible || end >= xAccessor(lastItem)) {
		const [rangeStart, rangeEnd] = initialXScale.range();
		const [newRangeStart, newRangeEnd] = updatedXScale.range();
		const newDomainExtent = ((newRangeEnd - newRangeStart) / (rangeEnd - rangeStart)) * (end - start);
		const newStart = maintainPointsPerPixelOnResize ? end - newDomainExtent : start;

		const lastItemX = initialXScale(xAccessor(lastItem));
		const response = filterData(fullData, [newStart, end], xAccessor, updatedXScale, {
			fallbackStart: start,
			fallbackEnd: { lastItem, lastItemX }
		});
		initialPlotData = response.plotData;
		updatedXScale.domain(response.domain);
	} else if (lastItemWasVisible && end < xAccessor(lastItem)) {
		const dx = initialXScale(xAccessor(lastItem)) - initialXScale.range()[1];
		const [newStart, newEnd] = initialXScale
			.range()
			.map((x: any) => x + dx)
			.map(initialXScale.invert);

		const response = filterData(fullData, [newStart, newEnd], xAccessor, updatedXScale);
		initialPlotData = response.plotData;
		updatedXScale.domain(response.domain); // if last item was visible, then shift
	}
	// plotData = getDataOfLength(fullData, showingInterval, plotData.length)
	const plotData = postCalculator(initialPlotData);
	const chartConfig = getChartConfigWithUpdatedYScales(
		getNewChartConfig(dimensions, children, initialChartConfig),
		{ plotData, xAccessor, displayXAccessor, fullData },
		updatedXScale.domain(),
		chartView
	);

	return {
		xScale: updatedXScale,
		xAccessor,
		chartConfig,
		plotData,
		fullData,
		filterData
	};
}

function calculateState(props: any) {
	const { xAccessor: inputXAccesor, xExtents: xExtentsProp, data, padding, flipXScale } = props;

	if (process.env.NODE_ENV !== 'production' && isDefined(props.xScale.invert)) {
		for (let i = 1; i < data.length; i++) {
			const prev = data[i - 1];
			const curr = data[i];
			if (inputXAccesor(prev) > inputXAccesor(curr)) {
				throw new Error("'data' is not sorted on 'xAccessor', send 'data' sorted in ascending order of 'xAccessor'");
			}
		}
	}

	const direction = getXScaleDirection(flipXScale);
	const dimensions = getDimensions(props);

	const extent =
		typeof xExtentsProp === 'function'
			? xExtentsProp(data)
			: d3Extent(xExtentsProp.map((d: any) => functor(d)).map((each: any) => each(data, inputXAccesor)));

	const { xAccessor, displayXAccessor, xScale, fullData, filterData } = calculateFullData(props);
	const updatedXScale = setXRange(xScale, dimensions, padding, direction);

	const { plotData, domain } = filterData(fullData, extent, inputXAccesor, updatedXScale);

	if (process.env.NODE_ENV !== 'production' && plotData.length <= 1) {
		throw new Error(`Showing ${plotData.length} datapoints, review the 'xExtents' prop of ChartCanvas`);
	}
	return {
		plotData,
		xScale: updatedXScale.domain(domain),
		xAccessor,
		displayXAccessor,
		fullData,
		filterData
	};
}

function setXRange(xScale: any, dimensions: any, padding: any, direction = 1) {
	if (xScale.rangeRoundPoints) {
		if (isNaN(padding)) throw new Error('padding has to be a number for ordinal scale');
		xScale.rangeRoundPoints([0, dimensions.width], padding);
	} else if (xScale.padding) {
		if (isNaN(padding)) throw new Error('padding has to be a number for ordinal scale');
		xScale.range([0, dimensions.width]);
		xScale.padding(padding / 2);
	} else {
		const { left, right } = isNaN(padding) ? padding : { left: padding, right: padding };
		if (direction > 0) {
			xScale.range([left, dimensions.width - right]);
		} else {
			xScale.range([dimensions.width - right, left]);
		}
	}
	return xScale;
}

function pinchCoordinates(pinch: any) {
	const { touch1Pos, touch2Pos } = pinch;

	return {
		topLeft: [Math.min(touch1Pos[0], touch2Pos[0]), Math.min(touch1Pos[1], touch2Pos[1])],
		bottomRight: [Math.max(touch1Pos[0], touch2Pos[0]), Math.max(touch1Pos[1], touch2Pos[1])]
	};
}

class ChartCanvas extends Component<any> {
	state: any = {};
	private subscriptions: any;
	private interactiveState: any;
	private panInProgress: any;
	private mutableState: any;
	private lastSubscriptionId: any;
	private eventCaptureNode: any;
	private canvasContainerNode: any;
	private fullData: any;
	private waitingForPinchZoomAnimationFrame: any;
	private finalPinch: any;
	private hackyWayToStopPanBeyondBounds__plotData: any;
	private hackyWayToStopPanBeyondBounds__domain: any;
	private waitingForPanAnimationFrame: any;
	private waitingForMouseMoveAnimationFrame: any;
	private prevMouseXY: any;

	static propTypes: any = {
		width: PropTypes.number.isRequired,
		height: PropTypes.number.isRequired,
		margin: PropTypes.object,
		ratio: PropTypes.number.isRequired,
		type: PropTypes.oneOf(['svg', 'hybrid']),
		pointsPerPxThreshold: PropTypes.number,
		minPointsPerPxThreshold: PropTypes.number,
		data: PropTypes.array.isRequired,
		xAccessor: PropTypes.func,
		xExtents: PropTypes.oneOfType([PropTypes.array, PropTypes.func]),
		zoomAnchor: PropTypes.func,
		className: PropTypes.string,
		seriesName: PropTypes.string.isRequired,
		zIndex: PropTypes.number,
		children: PropTypes.node.isRequired,
		xScale: PropTypes.func.isRequired,
		postCalculator: PropTypes.func,
		flipXScale: PropTypes.bool,
		useCrossHairStyleCursor: PropTypes.bool,
		padding: PropTypes.oneOfType([
			PropTypes.number,
			PropTypes.shape({
				left: PropTypes.number,
				right: PropTypes.number
			})
		]),
		defaultFocus: PropTypes.bool,
		zoomMultiplier: PropTypes.number,
		onLoadMore: PropTypes.func,
		displayXAccessor: function (props: any, propName: any /* , componentName */) {
			if (isNotDefined(props[propName])) {
				console.warn(
					'`displayXAccessor` is not defined,' +
						' will use the value from `xAccessor` as `displayXAccessor`.' +
						' This might be ok if you do not use a discontinuous scale' +
						' but if you do, provide a `displayXAccessor` prop to `ChartCanvas`'
				);
			} else if (typeof props[propName] !== 'function') {
				return new Error('displayXAccessor has to be a function');
			}
		},
		mouseMoveEvent: PropTypes.bool,
		panEvent: PropTypes.bool,
		clamp: PropTypes.oneOfType([PropTypes.string, PropTypes.bool, PropTypes.func]),
		zoomEvent: PropTypes.bool,
		onSelect: PropTypes.func,
		maintainPointsPerPixelOnResize: PropTypes.bool,
		disableInteraction: PropTypes.bool,
		chartView: PropTypes.string
	};

	static childContextTypes = {
		plotData: PropTypes.array,
		fullData: PropTypes.array,
		chartConfig: PropTypes.arrayOf(
			PropTypes.shape({
				id: PropTypes.oneOfType([PropTypes.number, PropTypes.string]).isRequired,
				origin: PropTypes.arrayOf(PropTypes.number).isRequired,
				padding: PropTypes.oneOfType([
					PropTypes.number,
					PropTypes.shape({
						top: PropTypes.number,
						bottom: PropTypes.number
					})
				]),
				yExtents: PropTypes.arrayOf(PropTypes.func),
				yExtentsProvider: PropTypes.func,
				yScale: PropTypes.func.isRequired,
				mouseCoordinates: PropTypes.shape({
					at: PropTypes.string,
					format: PropTypes.func
				}),
				width: PropTypes.number.isRequired,
				height: PropTypes.number.isRequired
			})
		).isRequired,
		xScale: PropTypes.func.isRequired,
		xAccessor: PropTypes.func.isRequired,
		displayXAccessor: PropTypes.func.isRequired,
		width: PropTypes.number.isRequired,
		height: PropTypes.number.isRequired,
		chartCanvasType: PropTypes.oneOf(['svg', 'hybrid']).isRequired,
		margin: PropTypes.object.isRequired,
		ratio: PropTypes.number.isRequired,
		getCanvasContexts: PropTypes.func,
		xAxisZoom: PropTypes.func,
		yAxisZoom: PropTypes.func,
		amIOnTop: PropTypes.func,
		redraw: PropTypes.func,
		subscribe: PropTypes.func,
		unsubscribe: PropTypes.func,
		setCursorClass: PropTypes.func,
		generateSubscriptionId: PropTypes.func,
		getMutableState: PropTypes.func
	};

	static defaultProps = {
		margin: { top: 20, right: 30, bottom: 30, left: 80 },
		type: 'hybrid',
		pointsPerPxThreshold: 2,
		minPointsPerPxThreshold: 1 / 100,
		className: 'react-stockchart',
		zIndex: 1,
		xExtents: [min, max],
		postCalculator: identity,
		padding: 0,
		xAccessor: identity,
		flipXScale: false,
		useCrossHairStyleCursor: true,
		defaultFocus: true,
		onLoadMore: noop,
		onSelect: noop,
		mouseMoveEvent: true,
		panEvent: true,
		zoomEvent: true,
		zoomMultiplier: 1.1,
		clamp: false,
		zoomAnchor: mouseBasedZoomAnchor,
		maintainPointsPerPixelOnResize: true,
		disableInteraction: false,
		chartView: 'price'
	};

	constructor(props: any) {
		super(props);
		this.getDataInfo = this.getDataInfo.bind(this);
		this.getCanvasContexts = this.getCanvasContexts.bind(this);

		this.handleMouseMove = this.handleMouseMove.bind(this);
		this.handleMouseEnter = this.handleMouseEnter.bind(this);
		this.handleMouseLeave = this.handleMouseLeave.bind(this);
		this.handleZoom = this.handleZoom.bind(this);
		this.handlePinchZoom = this.handlePinchZoom.bind(this);
		this.handlePinchZoomEnd = this.handlePinchZoomEnd.bind(this);
		this.handlePan = this.handlePan.bind(this);
		this.handlePanEnd = this.handlePanEnd.bind(this);
		this.handleClick = this.handleClick.bind(this);
		this.handleMouseDown = this.handleMouseDown.bind(this);
		this.handleDoubleClick = this.handleDoubleClick.bind(this);
		this.handleContextMenu = this.handleContextMenu.bind(this);
		this.handleDragStart = this.handleDragStart.bind(this);
		this.handleDrag = this.handleDrag.bind(this);
		this.handleDragEnd = this.handleDragEnd.bind(this);

		this.panHelper = this.panHelper.bind(this);
		this.pinchZoomHelper = this.pinchZoomHelper.bind(this);
		this.xAxisZoom = this.xAxisZoom.bind(this);
		this.yAxisZoom = this.yAxisZoom.bind(this);
		this.resetYDomain = this.resetYDomain.bind(this);
		this.calculateStateForDomain = this.calculateStateForDomain.bind(this);
		this.generateSubscriptionId = this.generateSubscriptionId.bind(this);
		this.draw = this.draw.bind(this);
		this.redraw = this.redraw.bind(this);
		this.getAllPanConditions = this.getAllPanConditions.bind(this);

		this.subscriptions = [];
		this.subscribe = this.subscribe.bind(this);
		this.unsubscribe = this.unsubscribe.bind(this);
		this.amIOnTop = this.amIOnTop.bind(this);
		this.saveEventCaptureNode = this.saveEventCaptureNode.bind(this);
		this.saveCanvasContainerNode = this.saveCanvasContainerNode.bind(this);
		this.setCursorClass = this.setCursorClass.bind(this);
		this.getMutableState = this.getMutableState.bind(this);
		// this.canvasDrawCallbackList = [];
		this.interactiveState = [];
		this.panInProgress = false;

		this.mutableState = {};
		this.lastSubscriptionId = 0;
	}

	getChildContext() {
		const dimensions = getDimensions(this.props);
		return {
			fullData: this.fullData,
			plotData: this.state.plotData,
			width: dimensions.width,
			height: dimensions.height,
			chartConfig: this.state.chartConfig,
			xScale: this.state.xScale,
			xAccessor: this.state.xAccessor,
			displayXAccessor: this.state.displayXAccessor,
			chartCanvasType: this.props.type,
			margin: this.props.margin,
			ratio: this.props.ratio,
			xAxisZoom: this.xAxisZoom,
			yAxisZoom: this.yAxisZoom,
			getCanvasContexts: this.getCanvasContexts,
			redraw: this.redraw,
			subscribe: this.subscribe,
			unsubscribe: this.unsubscribe,
			generateSubscriptionId: this.generateSubscriptionId,
			getMutableState: this.getMutableState,
			amIOnTop: this.amIOnTop,
			setCursorClass: this.setCursorClass
		};
	}
	UNSAFE_componentWillMount() {
		const { fullData, ...state } = resetChart(this.props, true, this.props.chartView);
		this.setState(state);
		this.fullData = fullData;
	}
	UNSAFE_componentWillReceiveProps(nextProps: any) {
		const reset = shouldResetChart(this.props, nextProps);

		const interaction = isInteractionEnabled(this.state.xScale, this.state.xAccessor, this.state.plotData);
		const { chartConfig: initialChartConfig } = this.state;

		let newState;
		if (!interaction || reset || !shallowEqual(this.props.xExtents, nextProps.xExtents)) {
			if (process.env.NODE_ENV !== 'production') {
				if (!interaction) log('RESET CHART, changes to a non interactive chart');
				else if (reset) log('RESET CHART, one or more of these props changed', CANDIDATES_FOR_RESET);
				else log('xExtents changed');
			}
			// do reset
			newState = resetChart(nextProps, false, this.props.chartView);
			this.mutableState = {};
		} else {
			const [start, end] = this.state.xScale.domain();
			const prevLastItem = last(this.fullData);

			const calculatedState = calculateFullData(nextProps);
			const { xAccessor } = calculatedState;
			const lastItemWasVisible = xAccessor(prevLastItem) <= end && xAccessor(prevLastItem) >= start;

			if (process.env.NODE_ENV !== 'production') {
				if (this.props.data !== nextProps.data)
					log(
						'data is changed but seriesName did not, change the seriesName if you wish to reset the chart and lastItemWasVisible = ',
						lastItemWasVisible
					);
				else log('Trivial change, may be width/height or type changed, but that does not matter');
			}

			newState = updateChart(calculatedState, this.state.xScale, nextProps, lastItemWasVisible, initialChartConfig, this.props.chartView);
		}

		const { fullData, ...state } = newState;

		if (this.panInProgress) {
			if (process.env.NODE_ENV !== 'production') {
				log('Pan is in progress');
			}
		} else {
			this.clearThreeCanvas();

			this.setState(state);
		}
		this.fullData = fullData;
	}

	shouldComponentUpdate() {
		return !this.panInProgress;
	}
	render() {
		const { type, height, width, margin, className, zIndex, defaultFocus, ratio, mouseMoveEvent, panEvent, zoomEvent } = this.props;
		const { useCrossHairStyleCursor, onSelect } = this.props;

		const { plotData, xScale, xAccessor, chartConfig } = this.state;
		const dimensions = getDimensions(this.props);

		const interaction = isInteractionEnabled(xScale, xAccessor, plotData);

		const cursorStyle = useCrossHairStyleCursor && interaction;
		const cursor = getCursorStyle();
		return (
			<div style={{ position: 'relative', width, height }} className={className} onClick={onSelect}>
				<CanvasContainer ref={this.saveCanvasContainerNode} type={type} ratio={ratio} width={width} height={height} zIndex={zIndex} />
				<svg className={className} width={width} height={height} style={{ position: 'absolute', zIndex: zIndex + 5 }}>
					{cursor}
					<defs>
						<clipPath id='chart-area-clip'>
							<rect x='0' y='0' width={dimensions.width} height={dimensions.height} />
						</clipPath>
						{chartConfig.map((each: any, idx: any) => (
							<clipPath key={idx} id={`chart-area-clip-${each.id}`}>
								<rect x='0' y='0' width={each.width} height={each.height} />
							</clipPath>
						))}
					</defs>
					<g transform={`translate(${margin.left + 0.5}, ${margin.top + 0.5})`}>
						<EventCapture
							ref={this.saveEventCaptureNode}
							useCrossHairStyleCursor={cursorStyle}
							mouseMove={mouseMoveEvent && interaction}
							zoom={zoomEvent && interaction}
							pan={panEvent && interaction}
							width={dimensions.width}
							height={dimensions.height}
							chartConfig={chartConfig}
							xScale={xScale}
							xAccessor={xAccessor}
							focus={defaultFocus}
							disableInteraction={this.props.disableInteraction}
							getAllPanConditions={this.getAllPanConditions}
							onContextMenu={this.handleContextMenu}
							onClick={this.handleClick}
							onDoubleClick={this.handleDoubleClick}
							onMouseDown={this.handleMouseDown}
							onMouseMove={this.handleMouseMove}
							onMouseEnter={this.handleMouseEnter}
							onMouseLeave={this.handleMouseLeave}
							onDragStart={this.handleDragStart}
							onDrag={this.handleDrag}
							onDragComplete={this.handleDragEnd}
							onZoom={this.handleZoom}
							onPinchZoom={this.handlePinchZoom}
							onPinchZoomEnd={this.handlePinchZoomEnd}
							onPan={this.handlePan}
							onPanEnd={this.handlePanEnd}
						/>

						<g className='react-stockcharts-avoid-interaction'>{this.props.children}</g>
					</g>
				</svg>
			</div>
		);
	}

	saveEventCaptureNode(node: any) {
		this.eventCaptureNode = node;
	}
	saveCanvasContainerNode(node: any) {
		this.canvasContainerNode = node;
	}
	getMutableState() {
		return this.mutableState;
	}
	getDataInfo() {
		return {
			...this.state,
			fullData: this.fullData
		};
	}
	getCanvasContexts() {
		if (this.canvasContainerNode) {
			return this.canvasContainerNode.getCanvasContexts();
		}
	}
	generateSubscriptionId() {
		this.lastSubscriptionId++;
		return this.lastSubscriptionId;
	}
	clearBothCanvas() {
		const canvases = this.getCanvasContexts();
		if (canvases && canvases.axes) {
			clearCanvas([canvases.axes, canvases.mouseCoord], this.props.ratio);
		}
	}
	clearMouseCanvas() {
		const canvases = this.getCanvasContexts();
		if (canvases && canvases.mouseCoord) {
			clearCanvas([canvases.mouseCoord], this.props.ratio);
		}
	}
	clearThreeCanvas() {
		const canvases = this.getCanvasContexts();
		if (canvases && canvases.axes) {
			clearCanvas([canvases.axes, canvases.mouseCoord, canvases.bg], this.props.ratio);
		}
	}
	subscribe(id: any, rest: any) {
		const {
			getPanConditions = functor({
				draggable: false,
				panEnabled: true
			})
		} = rest;
		this.subscriptions = this.subscriptions.concat({
			id,
			...rest,
			getPanConditions
		});
	}
	unsubscribe(id: any) {
		this.subscriptions = this.subscriptions.filter((each: any) => each.id !== id);
	}
	getAllPanConditions() {
		return this.subscriptions.map((each: any) => each.getPanConditions());
	}
	setCursorClass(className: any) {
		if (this.eventCaptureNode != null) {
			this.eventCaptureNode.setCursorClass(className);
		}
	}
	amIOnTop(id: any) {
		const dragableComponents = this.subscriptions.filter((each: any) => each.getPanConditions().draggable);

		return dragableComponents.length > 0 && last(dragableComponents).id === id;
	}
	handleContextMenu(mouseXY: any, e: any) {
		const { xAccessor, chartConfig, plotData, xScale } = this.state;

		const currentCharts = getCurrentCharts(chartConfig, mouseXY);
		const currentItem = getCurrentItem(xScale, xAccessor, mouseXY, plotData);

		this.triggerEvent(
			'contextmenu',
			{
				mouseXY,
				currentItem,
				currentCharts
			},
			e
		);
	}

	calculateStateForDomain(newDomain: any) {
		const { xAccessor, displayXAccessor, xScale: initialXScale, chartConfig: initialChartConfig, plotData: initialPlotData } = this.state;
		const { filterData } = this.state;
		const { fullData } = this;
		const { postCalculator } = this.props;

		const { plotData: beforePlotData, domain } = filterData(fullData, newDomain, xAccessor, initialXScale, {
			currentPlotData: initialPlotData,
			currentDomain: initialXScale.domain()
		});
		const plotData = postCalculator(beforePlotData);
		const updatedScale = initialXScale.copy().domain(domain);
		const chartConfig = getChartConfigWithUpdatedYScales(
			initialChartConfig,
			{ plotData, xAccessor, displayXAccessor, fullData },
			updatedScale.domain(),
			this.props.chartView
		);
		return {
			xScale: updatedScale,
			plotData,
			chartConfig
		};
	}
	pinchZoomHelper(initialPinch: any, finalPinch: any) {
		const { xScale: initialPinchXScale } = initialPinch;

		const { xScale: initialXScale, chartConfig: initialChartConfig, plotData: initialPlotData, xAccessor, displayXAccessor } = this.state;
		const { filterData } = this.state;
		const { fullData } = this;
		const { postCalculator } = this.props;

		const { topLeft: iTL, bottomRight: iBR } = pinchCoordinates(initialPinch);
		const { topLeft: fTL, bottomRight: fBR } = pinchCoordinates(finalPinch);

		const e = initialPinchXScale.range()[1];

		const xDash = Math.round(-(iBR[0] * fTL[0] - iTL[0] * fBR[0]) / (iTL[0] - iBR[0]));
		const yDash = Math.round(e + ((e - iBR[0]) * (e - fTL[0]) - (e - iTL[0]) * (e - fBR[0])) / (e - iTL[0] - (e - iBR[0])));

		const x = Math.round((-xDash * iTL[0]) / (-xDash + fTL[0]));
		const y = Math.round(e - ((yDash - e) * (e - iTL[0])) / (yDash + (e - fTL[0])));

		const newDomain = [x, y].map(initialPinchXScale.invert);

		const { plotData: beforePlotData, domain } = filterData(fullData, newDomain, xAccessor, initialPinchXScale, {
			currentPlotData: initialPlotData,
			currentDomain: initialXScale.domain()
		});

		const plotData = postCalculator(beforePlotData);
		const updatedScale = initialXScale.copy().domain(domain);

		const mouseXY = finalPinch.touch1Pos;
		const chartConfig = getChartConfigWithUpdatedYScales(
			initialChartConfig,
			{ plotData, xAccessor, displayXAccessor, fullData },
			updatedScale.domain(),
			this.props.chartView
		);
		const currentItem = getCurrentItem(updatedScale, xAccessor, mouseXY, plotData);

		return {
			chartConfig,
			xScale: updatedScale,
			plotData,
			mouseXY,
			currentItem
		};
	}
	cancelDrag() {
		this.eventCaptureNode.cancelDrag();
		this.triggerEvent('dragcancel');
	}
	handlePinchZoom(initialPinch: any, finalPinch: any, e: any) {
		if (!this.waitingForPinchZoomAnimationFrame) {
			this.waitingForPinchZoomAnimationFrame = true;
			const state = this.pinchZoomHelper(initialPinch, finalPinch);

			this.triggerEvent('pinchzoom', state, e);

			this.finalPinch = finalPinch;

			requestAnimationFrame(() => {
				this.clearBothCanvas();
				this.draw({ trigger: 'pinchzoom' });
				this.waitingForPinchZoomAnimationFrame = false;
			});
		}
	}
	handlePinchZoomEnd(initialPinch: any, e: any) {
		const { xAccessor } = this.state;

		if (this.finalPinch) {
			const state = this.pinchZoomHelper(initialPinch, this.finalPinch);
			const { xScale } = state;
			this.triggerEvent('pinchzoom', state, e);

			this.finalPinch = null;

			this.clearThreeCanvas();

			const { fullData } = this;
			const firstItem = head(fullData);

			const start = head(xScale.domain());
			const end = xAccessor(firstItem);
			const { onLoadMore } = this.props;

			this.setState(state, () => {
				if (start < end) {
					onLoadMore(start, end);
				}
			});
		}
	}
	handleZoom(zoomDirection: any, mouseXY: any, e: any) {
		if (this.panInProgress) return;
		const { xAccessor, xScale: initialXScale, plotData: initialPlotData } = this.state;
		const { zoomMultiplier, zoomAnchor } = this.props;
		const { fullData } = this;
		const item = zoomAnchor({
			xScale: initialXScale,
			xAccessor,
			mouseXY,
			plotData: initialPlotData,
			fullData
		});

		const cx = initialXScale(item);
		const c = zoomDirection > 0 ? 1 * zoomMultiplier : 1 / zoomMultiplier;
		const newDomain = initialXScale
			.range()
			.map((x: any) => cx + (x - cx) * c)
			.map(initialXScale.invert);
		const { xScale, plotData, chartConfig } = this.calculateStateForDomain(newDomain);

		const currentItem = getCurrentItem(xScale, xAccessor, mouseXY, plotData);
		const currentCharts = getCurrentCharts(chartConfig, mouseXY);

		this.clearThreeCanvas();

		const firstItem = head(fullData);

		const start = head(xScale.domain());
		const end = xAccessor(firstItem);
		const { onLoadMore } = this.props;

		this.mutableState = {
			mouseXY: mouseXY,
			currentItem: currentItem,
			currentCharts: currentCharts
		};

		this.triggerEvent(
			'zoom',
			{
				xScale,
				plotData,
				chartConfig,
				mouseXY,
				currentCharts,
				currentItem,
				show: true
			},
			e
		);

		this.setState(
			{
				xScale,
				plotData,
				chartConfig
			},
			() => {
				if (start < end) {
					onLoadMore(start, end);
				}
			}
		);
	}
	xAxisZoom(newDomain: any) {
		const { xScale, plotData, chartConfig } = this.calculateStateForDomain(newDomain);
		this.clearThreeCanvas();

		const { xAccessor } = this.state;
		const { fullData } = this;
		const firstItem = head(fullData);
		const start = head(xScale.domain());
		const end = xAccessor(firstItem);
		const { onLoadMore } = this.props;

		this.setState(
			{
				xScale,
				plotData,
				chartConfig
			},
			() => {
				if (start < end) onLoadMore(start, end);
			}
		);
	}
	yAxisZoom(chartId: any, newDomain: any) {
		this.clearThreeCanvas();
		const { chartConfig: initialChartConfig } = this.state;
		const chartConfig = initialChartConfig.map((each: any) => {
			if (each.id === chartId) {
				const { yScale } = each;
				return {
					...each,
					yScale: yScale.copy().domain(newDomain),
					yPanEnabled: true
				};
			} else {
				return each;
			}
		});

		this.setState({
			chartConfig
		});
	}
	triggerEvent(type: any, props?: any, e?: any) {
		this.subscriptions.forEach((each: any) => {
			const state = {
				...this.state,
				fullData: this.fullData,
				subscriptions: this.subscriptions
			};
			each.listener(type, props, state, e);
		});
	}
	draw(props: any) {
		this.subscriptions.forEach((each: any) => {
			if (isDefined(each.draw)) each.draw(props);
		});
	}
	redraw() {
		this.clearThreeCanvas();
		this.draw({ force: true });
	}
	panHelper(mouseXY: any, initialXScale: any, { dx, dy }: any, chartsToPan: any) {
		const { xAccessor, displayXAccessor, chartConfig: initialChartConfig } = this.state;
		const { filterData } = this.state;
		const { fullData } = this;
		const { postCalculator } = this.props;

		if (isNotDefined(initialXScale.invert))
			throw new Error(
				'xScale provided does not have an invert() method.' + 'You are likely using an ordinal scale. This scale does not support zoom, pan'
			);

		const newDomain = initialXScale
			.range()
			.map((x: any) => x - dx)
			.map(initialXScale.invert);

		const { plotData: beforePlotData, domain } = filterData(fullData, newDomain, xAccessor, initialXScale, {
			currentPlotData: this.hackyWayToStopPanBeyondBounds__plotData,
			currentDomain: this.hackyWayToStopPanBeyondBounds__domain
		});

		const updatedScale = initialXScale.copy().domain(domain);
		const plotData = postCalculator(beforePlotData);

		const currentItem = getCurrentItem(updatedScale, xAccessor, mouseXY, plotData);
		const chartConfig = getChartConfigWithUpdatedYScales(
			initialChartConfig,
			{ plotData, xAccessor, displayXAccessor, fullData },
			updatedScale.domain(),
			this.props.chartView,
			dy,
			chartsToPan
		);
		const currentCharts = getCurrentCharts(chartConfig, mouseXY);

		return {
			xScale: updatedScale,
			plotData,
			chartConfig,
			mouseXY,
			currentCharts,
			currentItem
		};
	}
	handlePan(mousePosition: any, panStartXScale: any, dxdy: any, chartsToPan: any, e: any) {
		if (!this.waitingForPanAnimationFrame) {
			this.waitingForPanAnimationFrame = true;

			this.hackyWayToStopPanBeyondBounds__plotData = this.hackyWayToStopPanBeyondBounds__plotData || this.state.plotData;
			this.hackyWayToStopPanBeyondBounds__domain = this.hackyWayToStopPanBeyondBounds__domain || this.state.xScale.domain();

			const state = this.panHelper(mousePosition, panStartXScale, dxdy, chartsToPan);

			this.hackyWayToStopPanBeyondBounds__plotData = state.plotData;
			this.hackyWayToStopPanBeyondBounds__domain = state.xScale.domain();

			this.panInProgress = true;

			this.triggerEvent('pan', state, e);

			this.mutableState = {
				mouseXY: state.mouseXY,
				currentItem: state.currentItem,
				currentCharts: state.currentCharts
			};
			requestAnimationFrame(() => {
				this.waitingForPanAnimationFrame = false;
				this.clearBothCanvas();
				this.draw({ trigger: 'pan' });
			});
		}
	}
	handlePanEnd(mousePosition: any, panStartXScale: any, dxdy: any, chartsToPan: any, e: any) {
		const state = this.panHelper(mousePosition, panStartXScale, dxdy, chartsToPan);
		this.hackyWayToStopPanBeyondBounds__plotData = null;
		this.hackyWayToStopPanBeyondBounds__domain = null;

		this.panInProgress = false;

		const { xScale, plotData, chartConfig } = state;

		this.triggerEvent('panend', state, e);

		requestAnimationFrame(() => {
			const { xAccessor } = this.state;
			const { fullData } = this;

			const firstItem = head(fullData);
			const start = head(xScale.domain());
			const end = xAccessor(firstItem);
			const { onLoadMore } = this.props;

			this.clearThreeCanvas();

			this.setState(
				{
					xScale,
					plotData,
					chartConfig
				},
				() => {
					if (start < end) onLoadMore(start, end);
				}
			);
		});
	}
	handleMouseDown(mousePosition: any, currentCharts: any, e: any) {
		this.triggerEvent('mousedown', this.mutableState, e);
	}
	handleMouseEnter(e: any) {
		this.triggerEvent(
			'mouseenter',
			{
				show: true
			},
			e
		);
	}
	handleMouseMove(mouseXY: any, inputType: any, e: any) {
		if (!this.waitingForMouseMoveAnimationFrame) {
			this.waitingForMouseMoveAnimationFrame = true;

			const { chartConfig, plotData, xScale, xAccessor } = this.state;
			const currentCharts = getCurrentCharts(chartConfig, mouseXY);
			const currentItem = getCurrentItem(xScale, xAccessor, mouseXY, plotData);
			this.triggerEvent(
				'mousemove',
				{
					show: true,
					mouseXY,
					// prevMouseXY is used in interactive components
					prevMouseXY: this.prevMouseXY,
					currentItem,
					currentCharts
				},
				e
			);

			this.prevMouseXY = mouseXY;
			this.mutableState = {
				mouseXY,
				currentItem,
				currentCharts
			};

			requestAnimationFrame(() => {
				this.clearMouseCanvas();
				this.draw({ trigger: 'mousemove' });
				this.waitingForMouseMoveAnimationFrame = false;
			});
		}
	}
	handleMouseLeave(e: any) {
		this.triggerEvent('mouseleave', { show: false }, e);
		this.clearMouseCanvas();
		this.draw({ trigger: 'mouseleave' });
	}
	handleDragStart({ startPos }: any, e: any) {
		this.triggerEvent('dragstart', { startPos }, e);
	}
	handleDrag({ startPos, mouseXY }: any, e: any) {
		const { chartConfig, plotData, xScale, xAccessor } = this.state;
		const currentCharts = getCurrentCharts(chartConfig, mouseXY);
		const currentItem = getCurrentItem(xScale, xAccessor, mouseXY, plotData);

		this.triggerEvent(
			'drag',
			{
				startPos,
				mouseXY,
				currentItem,
				currentCharts
			},
			e
		);

		this.mutableState = {
			mouseXY,
			currentItem,
			currentCharts
		};

		requestAnimationFrame(() => {
			this.clearMouseCanvas();
			this.draw({ trigger: 'drag' });
		});
	}
	handleDragEnd({ mouseXY }: any, e: any) {
		this.triggerEvent('dragend', { mouseXY }, e);

		requestAnimationFrame(() => {
			this.clearMouseCanvas();
			this.draw({ trigger: 'dragend' });
		});
	}

	handleClick(mousePosition: any, e: any) {
		this.triggerEvent('click', this.mutableState, e);

		requestAnimationFrame(() => {
			this.clearMouseCanvas();
			this.draw({ trigger: 'click' });
		});
	}

	handleDoubleClick(mousePosition: any, e: any) {
		this.triggerEvent('dblclick', {}, e);
	}

	resetYDomain(chartId: any) {
		const { chartConfig } = this.state;
		let changed = false;
		const newChartConfig = chartConfig.map((each: any) => {
			if ((isNotDefined(chartId) || each.id === chartId) && !shallowEqual(each.yScale.domain(), each.realYDomain)) {
				changed = true;
				return {
					...each,
					yScale: each.yScale.domain(each.realYDomain),
					yPanEnabled: false
				};
			}
			return each;
		});

		if (changed) {
			this.clearThreeCanvas();
			this.setState({
				chartConfig: newChartConfig
			});
		}
	}
	static ohlcv: (d: any) => {
		date: string; // Assuming 'date' is a string, adjust the type as needed
		open: number; // Assuming 'open' is a number, adjust the type as needed
		high: number;
		low: number;
		close: number;
		volume: number;
	};
}

function isInteractionEnabled(xScale: any, xAccessor: any, data: any) {
	const interaction = !isNaN(xScale(xAccessor(head(data)))) && isDefined(xScale.invert);
	return interaction;
}

function values(func: any) {
	return (d: any) => {
		const obj = func(d);
		if (isObject(obj)) {
			return mapObject(obj);
		}
		return obj;
	};
}

function setRange(scale: any, height: any, padding: any, flipYScale: any) {
	if (scale.rangeRoundPoints || isNotDefined(scale.invert)) {
		if (isNaN(padding)) throw new Error('padding has to be a number for ordinal scale');
		if (scale.rangeRoundPoints) scale.rangeRoundPoints(flipYScale ? [0, height] : [height, 0], padding);
		if (scale.rangeRound) scale.range(flipYScale ? [0, height] : [height, 0]).padding(padding);
	} else {
		const { top, bottom } = isNaN(padding) ? padding : { top: padding, bottom: padding };

		scale.range(flipYScale ? [top, height - bottom] : [height - bottom, top]);
	}
	return scale;
}

function yDomainFromYExtents(yExtents: any, yScale: any, plotData: any) {
	const yValues = yExtents.map((eachExtent: any) => {
		return plotData.map(values(eachExtent));
	});
	const allYValues: any = flattenDeep(yValues);
	const realYDomain = yScale.invert ? extent(allYValues) : allYValues;
	return realYDomain;
}

function getChartConfigWithUpdatedYScales(
	chartConfig: any,
	{ plotData, xAccessor, displayXAccessor, fullData }: any,
	xDomain: any,
	chartView?: string,
	dy?: any,
	chartsToPan?: any
) {
	const yDomains = chartConfig.map(({ yExtentsCalculator, yExtents, yScale }: any) => {
		const realYDomain = isDefined(yExtentsCalculator)
			? yExtentsCalculator({ plotData, xDomain, xAccessor, displayXAccessor, fullData })
			: yDomainFromYExtents(yExtents, yScale, plotData);

		const yDomainDY = isDefined(dy)
			? yScale
					.range()
					.map((each: any) => each - dy)
					.map(yScale.invert)
			: yScale.domain();
		return {
			realYDomain,
			yDomainDY,
			prevYDomain: yScale.domain()
		};
	});

	const combine = zipper().combine((config: any, { realYDomain, yDomainDY, prevYDomain }: any) => {
		const { id, padding, height, yScale, yPan, flipYScale, yPanEnabled = false } = config;
		const another = isDefined(chartsToPan) ? chartsToPan.indexOf(id) > -1 : true;
		const yPaddingBottom = (realYDomain[1] - realYDomain[0]) * (45 / 100);
		const isLogarithmicScale = chartView === ScaleType.LOGARITHAMIC_AXIS;
		const scale = isLogarithmicScale
			? realYDomain
			: [realYDomain[0] - yPaddingBottom, chartView === ScaleType.SESSION_CHART ? realYDomain[1] : realYDomain[1] + yPaddingBottom];
		const domain = yPan && yPanEnabled ? (another ? yDomainDY : prevYDomain) : scale;
		const newYScale = setRange(yScale.copy().domain(domain), height, padding, flipYScale);

		return {
			...config,
			yScale: newYScale,
			realYDomain: realYDomain
		};
	});

	const updatedChartConfig = combine(chartConfig, yDomains);
	return updatedChartConfig;
}

ChartCanvas.propTypes = {
	width: PropTypes.number.isRequired,
	height: PropTypes.number.isRequired,
	margin: PropTypes.object,
	ratio: PropTypes.number.isRequired,
	type: PropTypes.oneOf(['svg', 'hybrid']),
	pointsPerPxThreshold: PropTypes.number,
	minPointsPerPxThreshold: PropTypes.number,
	data: PropTypes.array.isRequired,
	xAccessor: PropTypes.func,
	xExtents: PropTypes.oneOfType([PropTypes.array, PropTypes.func]),
	zoomAnchor: PropTypes.func,

	className: PropTypes.string,
	seriesName: PropTypes.string.isRequired,
	zIndex: PropTypes.number,
	children: PropTypes.node.isRequired,
	xScale: PropTypes.func.isRequired,
	postCalculator: PropTypes.func,
	flipXScale: PropTypes.bool,
	useCrossHairStyleCursor: PropTypes.bool,
	padding: PropTypes.oneOfType([
		PropTypes.number,
		PropTypes.shape({
			left: PropTypes.number,
			right: PropTypes.number
		})
	]),
	defaultFocus: PropTypes.bool,
	zoomMultiplier: PropTypes.number,
	onLoadMore: PropTypes.func,
	displayXAccessor: function (props: any, propName: any /* , componentName */) {
		if (isNotDefined(props[propName])) {
			console.warn(
				'`displayXAccessor` is not defined,' +
					' will use the value from `xAccessor` as `displayXAccessor`.' +
					' This might be ok if you do not use a discontinuous scale' +
					' but if you do, provide a `displayXAccessor` prop to `ChartCanvas`'
			);
		} else if (typeof props[propName] !== 'function') {
			return new Error('displayXAccessor has to be a function');
		}
	},
	mouseMoveEvent: PropTypes.bool,
	panEvent: PropTypes.bool,
	clamp: PropTypes.oneOfType([PropTypes.string, PropTypes.bool, PropTypes.func]),
	zoomEvent: PropTypes.bool,
	onSelect: PropTypes.func,
	maintainPointsPerPixelOnResize: PropTypes.bool,
	disableInteraction: PropTypes.bool
};

ChartCanvas.defaultProps = {
	margin: { top: 20, right: 30, bottom: 30, left: 80 },
	type: 'hybrid',
	pointsPerPxThreshold: 2,
	minPointsPerPxThreshold: 1 / 100,
	className: 'react-stockchart',
	zIndex: 1,
	xExtents: [min, max],
	postCalculator: identity,
	padding: 0,
	xAccessor: identity,
	flipXScale: false,
	useCrossHairStyleCursor: true,
	defaultFocus: true,
	onLoadMore: noop,
	onSelect: noop,
	mouseMoveEvent: true,
	panEvent: true,
	zoomEvent: true,
	zoomMultiplier: 1.1,
	clamp: false,
	zoomAnchor: mouseBasedZoomAnchor,
	maintainPointsPerPixelOnResize: true,
	disableInteraction: false,
	chartView: 'price'
};

ChartCanvas.childContextTypes = {
	plotData: PropTypes.array,
	fullData: PropTypes.array,
	chartConfig: PropTypes.arrayOf(
		PropTypes.shape({
			id: PropTypes.oneOfType([PropTypes.number, PropTypes.string]).isRequired,
			origin: PropTypes.arrayOf(PropTypes.number).isRequired,
			padding: PropTypes.oneOfType([
				PropTypes.number,
				PropTypes.shape({
					top: PropTypes.number,
					bottom: PropTypes.number
				})
			]),
			yExtents: PropTypes.arrayOf(PropTypes.func),
			yExtentsProvider: PropTypes.func,
			yScale: PropTypes.func.isRequired,
			mouseCoordinates: PropTypes.shape({
				at: PropTypes.string,
				format: PropTypes.func
			}),
			width: PropTypes.number.isRequired,
			height: PropTypes.number.isRequired
		})
	).isRequired,
	xScale: PropTypes.func.isRequired,
	xAccessor: PropTypes.func.isRequired,
	displayXAccessor: PropTypes.func.isRequired,
	width: PropTypes.number.isRequired,
	height: PropTypes.number.isRequired,
	chartCanvasType: PropTypes.oneOf(['svg', 'hybrid']).isRequired,
	margin: PropTypes.object.isRequired,
	ratio: PropTypes.number.isRequired,
	getCanvasContexts: PropTypes.func,
	xAxisZoom: PropTypes.func,
	yAxisZoom: PropTypes.func,
	amIOnTop: PropTypes.func,
	redraw: PropTypes.func,
	subscribe: PropTypes.func,
	unsubscribe: PropTypes.func,
	setCursorClass: PropTypes.func,
	generateSubscriptionId: PropTypes.func,
	getMutableState: PropTypes.func
};

ChartCanvas.ohlcv = (d: any) => ({ date: d.date, open: d.open, high: d.high, low: d.low, close: d.close, volume: d.volume });

export default ChartCanvas;
