import { isEmpty } from 'lodash';
import { useSelector } from 'react-redux';
import React, { useCallback, useEffect, useState } from 'react';

import { State } from 'shared/interface';
import { useQueryParams } from 'shared/hooks/useQueryParams';
import httpService from 'shared/services/http.service';
import { API_CONFIG } from 'shared/constants/constants';
import { chartInfoTableData } from 'shared/util/utility';
import { PbBody, PbCell, PbHead, PbRow, PbTable } from 'shared/components/table';

import { IChartInformation } from '../interface/futureTable';
import localizationConstants from 'shared/util/translation/constants';
import i18n from 'shared/util/localization';
import { Translate } from 'shared/util/translation/translate';

interface IProps {
	className?: string;
}

const ChartInformationTable: React.FC<IProps> = (props) => {
	const { className } = props;
	const { symbol, boerse } = useQueryParams();
	const [information, setInformation] = useState<IChartInformation>({} as IChartInformation);
	const { language, admin } = useSelector((state: State) => state.siteConfig);
	const { t } = i18n;
	const chartUpdate: { [key: string]: string } = {
		D: `${t(localizationConstants.chartUpdateDaily)}`,
		M: `${t(localizationConstants.chartUpdateMonthly)}`,
		W: `${t(localizationConstants.chartUpdateWeekly)}`,
		Q: `${t(localizationConstants.chartUpdateQuarterly)}`
	};

	const getInformationData = useCallback(() => {
		httpService
			.get(`${API_CONFIG.path.chartInformation}?symbol=${encodeURIComponent(symbol as string)}&boerse=${encodeURIComponent(boerse as string)}`)
			.then((response) => {
				setInformation(response);
			})
			.catch((err) => console.error(err));
	}, [boerse, symbol]);

	useEffect(() => {
		getInformationData();
	}, [getInformationData]);
	return (
		<div className={`${className}`}>
			<PbTable className='chart-information-table'>
				<PbHead className='information-header'>
					<PbRow>
						<PbCell className='font--16px font-weight--700 line-height--20px color-white' colSpan={2} header>
							<Translate text={localizationConstants.informations} />
						</PbCell>
					</PbRow>
				</PbHead>
				<PbBody>
					{!isEmpty(information) &&
						chartInfoTableData(information, language, admin, chartUpdate).map((info) => {
							const { Icon } = info;
							return (
								<PbRow key={info.label}>
									<PbCell className='width-20 font--14px font-weight--400 line-height--20px'>
										<div>
											<span className='mr-10'>
												<Icon />
											</span>
											{info.label}
										</div>
									</PbCell>
									<PbCell className='font--14px font-weight--400 line-height--20px'>{info.value}</PbCell>
								</PbRow>
							);
						})}
				</PbBody>
			</PbTable>
		</div>
	);
};

export default ChartInformationTable;
