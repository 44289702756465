import React from 'react';
import { Link, useLocation } from 'react-router-dom';

import withRouter from 'shared/hoc/withRouter';
import {
	AdminMenuIcon,
	AlertMenuIcon,
	DashboardIcon,
	ForecastIcon,
	MaterialMenuIcon,
	MenuDropDownCloseIcon,
	MenuDropDownOpenIcon,
	NewsMenuIcon,
	TenderMenuIcon
} from 'shared/icons/icon';
import { INavArray, RouteComponentParams } from 'shared/interface';
import i18n from 'shared/util/localization';

interface Props {
	menu: INavArray;
	isOpen: boolean;
	toggleIsOpen: (name: string) => void;
	alertCount?: {
		[key: string]: number;
	};
	onClickSideNav: () => void;
	id: string;
}

const getPathElements = (path: string) => path.split('/').filter((str) => str !== '');

interface Props extends RouteComponentParams {}

const NavItem: React.FC<Props & RouteComponentParams> = (props) => {
	const { t } = i18n;
	const location = useLocation();
	const currentPath = getPathElements(location.pathname);
	const isActive = props.menu.id.toLowerCase() === currentPath[0] || props.isOpen;

	return (
		<li
			id={props.id}
			className={`${props.menu.id.toLowerCase() === currentPath[0] ? 'active' : ''} side-menu-shirnked`}
			onMouseDown={(e) => {
				if (props.menu.name === 'alerts') {
					e.stopPropagation();
					props.onClickSideNav();
				}
			}}
		>
			<Link to={props.menu.route} onClick={() => props.toggleIsOpen(props.menu.id)} className='d-flex align-items-center position--relative'>
				{ICONS[props.menu.id]}
				<span className='nav-label font--16px ml-10 font-weight--500 line-height--22px'>{t(props.menu.name)}</span>
				{props.menu.name === 'alerts' && props.alertCount !== undefined && Number(props.alertCount.total) !== 0 && (
					<p className={`d-flex alert-count align-items-center justify-content-center ml-8 font-weight--500 line-height--20px`}>
						{props.alertCount.total !== undefined && (props.alertCount.total > 100 ? '99+' : props.alertCount.total)}
					</p>
				)}
			</Link>
			{props.menu.subMenu && (
				<div
					className='position--absolute submenu-icon'
					onClick={() => {
						props.toggleIsOpen(props.menu.id);
					}}
				>
					{isActive ? (
						<MenuDropDownOpenIcon className='side--menu-icon cursor-pointer' />
					) : (
						<MenuDropDownCloseIcon className='side--menu-icon cursor-pointer' />
					)}
				</div>
			)}
			{props.menu.subMenu && isActive && (
				<ul className={isActive ? 'pl--20 navbar-submenu--open' : 'navbar-submenu--close'}>
					{props.menu.subMenu.map((subOptions, index) => {
						return (
							<li className={`${isActive ? 'active' : ''} submenu--list`} key={index}>
								<Link to={subOptions.route} className='d-flex align-items-center'>
									<span className={subOptions.route === location.pathname ? 'menu--submenu-active  ml-10' : 'menu--submenu  ml-10'}>
										{t(subOptions.name)}
									</span>
								</Link>
							</li>
						);
					})}
				</ul>
			)}
		</li>
	);
};

export default withRouter(NavItem);

const ICONS: { [key: string]: JSX.Element } = {
	dashboard: <DashboardIcon />,
	materials: <MaterialMenuIcon />,
	news: <NewsMenuIcon />,
	tenders: <TenderMenuIcon />,
	forecasts: <ForecastIcon className='admin-tab--icon fill-none' fill='#FFFFFF' stroke='#FFFFFF' />,
	alert: <AlertMenuIcon />,
	admin: <AdminMenuIcon />
};
