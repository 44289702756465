import { en } from './en';

export interface Translation {
	[key: string]: string;
	showPartnerLinkLine: string;
	animateWidgetLine: string;
	animation: string;
	personalize: string;
	registered: string;
	customerNr: string;
	freemium: string;
	customer: string;
	noTendersSaved: string;
	noEarlyProjectInfoSaved: string;
	tendersAndMore: string;
	searchMaterialEtc: string;
	searchNewsEtc: string;
	frozen: string;
	unfreeze: string;
	freeze: string;
	copyToClipboard: string;
	somethingWentWrong: string;
	foreignTradeNews: string;
	adminDownloadIntraday: string;
	passwordConfirmation: string;
	clickHereTo: string;
	forgotPasswordWaitMessage: string;
	oldPasswordIsRequired: string;
	passwordMatch: string;
	spaceNotExists: string;
	passwordCriteria: string;
	oneSpecialChar: string;
	oneNumberValidation: string;
	oneUppercaseOneLowercase: string;
	atLeast8Characters: string;
	matfoxxSettings: string;
	matfoxxNotifications: string;
	matfoxxNotificationOff: string;
	matfoxxNotificationOn: string;
	notificationPeriod: string;
	notificationAutomatically: string;
	until: string;
	filterNotifications: string;
	quoteOTheDay: string;
	matflixxOffers: string;
	updateGroup: string;
	subGroup: string;
	deleteCourseList: string;
	createGroup: string;
	editModelParameters: string;
	addToCostModal: string;
	matflixxModals: string;
	addMatflixxModals: string;
	customizeCostModal: string;
	sinceOneQuater: string;
	sinceOneYear: string;
	sinceOneMonth: string;
	priceDriver: string;
	share: string;
	shares: string;
	updateOnDashboard: string;
	myCostModal: string;
	dashboard: string;
	customize: string;
	favorites: string;
	myData: string;
	news: string;
	myNewsAndForecast: string;
	addShortcut: string;
	myShortcut: string;
	designation: string;
	pleseSelectFieldMessage: string;
	targetUrl: string;
	enterValidUrlAddress: string;
	urlForLogoPriview: string;
	rawMaterials: string;
	selectedMaterials: string;
	selectedNews: string;
	addWidgetToDashboard: string;
	update: string;
	submit: string;
	delete: string;
	edit: string;
	showAll: string;
	date: string;
	price: string;
	change: string;
	latestNews: string;
	addWidget: string;
	allSectors: string;
	collapsePanel: string;
	noCourseListing: string;
	convert: string;
	addToDashboard: string;
	exportCorseListAsExcel: string;
	advanceSearchForCourses: string;
	seek: string;
	searchPatterns: string;
	advanceSearchPlaceHolder: string;
	duration: string;
	doNotMetter: string;
	withoutDueDate: string;
	withoutFutures: string;
	futurePerptualContracsOnly: string;
	futureWithoutEndlessContract: string;
	back: string;
	materials: string;
	addFilterToDashboard: string;
	chooseDateRange: string;
	cancel: string;
	searchNews: string;
	searchNewsMaterialEtc: string;
	all: string;
	noEntriesFound: string;
	tryUsingDiffKeyword: string;
	more: string;
	notification: string;
	profile: string;
	logout: string;
	login: string;
	emailAddress: string;
	password: string;
	exportHistoryAsCSV: string;
	exportDataAsExcel: string;
	add: string;
	maturityOverview: string;
	exportPriceAsExcel: string;
	addWidgetToScreeen: string;
	setUpPriceAlert: string;
	addChartToDashboard: string;
	courseListings: string;
	renameCourseList: string;
	rename: string;
	extinguish: string;
	newsFilterTags: string;
	alerts: string;
	notifyAtTime: string;
	noteMessageForAllKeywordAppears: string;
	addNews: string;
	addNewsDashboard: string;
	currnetCourse: string;
	reportIf: string;
	courseExceeded: string;
	courseUndershot: string;
	absPerDeviationExceeded: string;
	comparativeValue: string;
	dateOfExpiry: string;
	addCourseAlert: string;
	messageAlerts: string;
	keywords: string;
	exmpleEleGas: string;
	forecasts: string;
	name: string;
	noNewsHeading: string;
	createNewPriceList: string;
	addAnotherOneLanguage: string;
	view: string;
	uploadFile: string;
	maturity: string;
	symbol: string;
	visible: string;
	notVisible: string;
	pricesTime: string;
	selectAll: string;
	hideSelected: string;
	deleteSelected: string;
	editUserData: string;
	save: string;
	generalInfo: string;
	salutation: string;
	mr: string;
	ms: string;
	surname: string;
	company: string;
	contacts: string;
	email: string;
	permissionData: string;
	status: string;
	phoneNumber: string;
	active: string;
	admin: string;
	inactive: string;
	validUntill: string;
	apiInterfaceActive: string;
	apiKey: string;
	comment: string;
	superAdminMessage: string;
	adminMessage: string;
	allMaterials: string;
	myMaterials: string;
	search: string;
	saveMessage: string;
	constModels: string;
	courses: string;
	dataProcessorMessage: string;
	editorMessage: string;
	API: string;
	statistics: string;
	validity: string;
	markant: string;
	manageData: string;
	permission: string;
	ownDataUpload: string;
	invisible: string;
	whiteLabelAdmin: string;
	groupName: string;
	files: string;
	addFile: string;
	dragAndDropToUploadFile: string;
	uploaded: string;
	timeLimit: string;
	occurred: string;
	deactive: string;
	isin: string;
	exchange: string;
	dataProvider: string;
	source: string;
	cpCustomer: string;
	courseTime: string;
	country: string;
	currency: string;
	pleaseSelectACategory: string;
	chooseCountry: string;
	runTime: string;
	chooseCurrency: string;
	advanceSearchfForPrice: string;
	advanceSearchTableText: string;
	advanceSearchTableDescription: string;
	tip: string;
	myDashboard: string;
	line: string;
	area: string;
	candle: string;
	chartType: string;
	span: string;
	indicators: string;
	addToPrice: string;
	noUserFound: string;
	currentPrice: string;
	alertCurrency: string;
	addedToDashboard: string;
	updatedToDashboard: string;
	exportData: string;
	selectFileFormat: string;
	users: string;
	data: string;
	whiteLabel: string;
	matfoxx: string;
	advancedSearchResult: string;
	advancedSearchTip: string;
	adminDownloadHistory: string;
	addToMbiList: string;
	assignMaterialToUser: string;
	dataEditing: string;
	addCourseToCourseList: string;
	myMaterialsEmptyTitle: string;
	myMaterialEmptyDescription: string;
	profileData: string;
	profileInfo: string;
	profileSaveChanges: string;
	profilePhoto: string;
	profileAddFile: string;
	profileDragandDrop: string;
	profileSavePhotos: string;
	profileDeletePhotos: string;
	profileGeneralData: string;
	profileCurrentEmail: string;
	profileChangePassword: string;
	searchUsers: string;
	general: string;
	registerNow: string;
	city: string;
	position: string;
	postcode: string;
	required: string;
	firstname: string;
	download: string;
	imprint: string;
	settings: string;
	removed: string;
	takeOver: string;
	abort: string;
	days: string;
	setPeriod: string;
	onboardSecondTitle: string;
	createPassword: string;
	futures: string;
	masterExportExcel: string;
	enterValuesGreaterThan: string;
	enterValuesLessThan: string;
	comparisonValues: string;
	comparisonValueGreaterThanZero: string;
	numberIsTooBig: string;
	courseDifference: string;
	selectFromTheDropDown: string;
	title: string;
	url: string;
	urlplaceholder: string;
	registrationValidationType: string;
	addShortCutDeleteLink: string;
	comparativeSearchPlaceholder: string;
	month: string;
	months: string;
	year: string;
	emptyChartMessage: string;
	custom: string;
	ema: string;
	sma: string;
	bb: string;
	tma: string;
	wma: string;
	atr: string;
	rsi: string;
	macd: string;
	sto: string;
	lowDataChartMessage: string;
	choose: string;
	noAlertFound: string;
	loadingChartData: string;
	positiveDifference: string;
	negativeDifference: string;
	deleteDashboardWidget: string;
	updateChartToDashboard: string;
	daily: string;
	weekly: string;
	monthly: string;
	updateToDashBoard: string;
	addMaterials: string;
	addNewsAndForecast: string;
	unlimited: string;
	markAllAsRead: string;
	otherChanges: string;
	materialAlert: string;
	newslAlert: string;
	actual: string;
	hist: string;
	high: string;
	low: string;
	histDate: string;
	unit: string;
	kurszeit: string;
	dueDate: string;
	mbiListPlaceHolder: string;
	isRequired: string;
	confirmLogout: string;
	yes: string;
	no: string;
	faq: string;
	almostThere: string;
	weNeedInfo: string;
	von: string;
	bis: string;
	protocol: string;
	categoryIncludeMessage: string;
	userIsAdminMessage: string;
	editNews: string;
	deleteNews: string;
	adminPriceDownload: string;
	userId: string;
	uploadedBy: string;
	fileName: string;
	inserted: string;
	updated: string;
	processes: string;
	mistake: string;
	noGroup: string;
	enterEmail: string;
	forgotPasswordNote: string;
	requestNewPassword: string;
	alreadyRegistered: string;
	here: string;
	notSubscribeYet: string;
	register: string;
	now: string;
	passwordForgotten: string;
	testForFree: string;
	noSavedNewsFound: string;
	saveChanges: string;
	discard: string;
	lastName: string;
	removeFromDashboard: string;
	noMaterialFound: string;
	newsSections: string;
	itemChoosen: string;
	takeOverAccount: string;
	takeOverFirstMessage: string;
	takeOverSecondMessage: string;
	group: string;
	authorized: string;
	mainCourse: string;
	alertWhen: string;
	entered: string;
	newsFilterKeyWords: string;
	deleteAlertMessage: string;
	visibility: string;
	hidden: string;
	addPartnersLink: string;
	create: string;
	savedNews: string;
	shortcutCreated: string;
	shortcutUpdated: string;
	shortcutDeleted: string;
	widgetSwiped: string;
	grantPermissionToUser: string;
	deleteNewsArticle: string;
	deleteFromCourseList: string;
	addedToList: string;
	forgotPassword: string;
	assign: string;
	priceListIsEmpty: string;
	emptyPriceListMessage: string;
	deleteNewsPopupMessage: string;
	validUrlErrorMessage: string;
	addInformation: string;
	enterNote: string;
	choosePriority: string;
	normal: string;
	critical: string;
	editInformation: string;
	authorizationElement: string;
	userList: string;
	masterData: string;
	backpack: string;
	createNewBackpack: string;
	editBackpack: string;
	downloadMasterData: string;
	comparisionValueShouldNotBeEmpty: string;
	reset: string;
	whiteLabelFormTitle: string;
	colors: string;
	courseListNameHasBeenSaved: string;
	courseListDeleted: string;
	noCurrentData: string;
	maximumLimitReached: string;
	noNewsArticleFound: string;
	additionalInfo: string;
	chartUpdateDaily: string;
	chartUpdateWeekly: string;
	chartUpdateMonthly: string;
	chartUpdateQuarterly: string;
	listName: string;
	partnerLinks: string;
	addAlertDropDownPlaceholder: string;
	glossary: string;
	normalization: string;
	upload: string;
	listofOffers: string;
	myModels: string;
	exportAsExcel: string;
	exportAsCSV: string;
	createNewCostModal: string;
	noDataFound: string;
	modalName: string;
	sectors: string;
	makeThisModelVisible: string;
	ingridients: string;
	number: string;
	displayName: string;
	amount: string;
	ratio: string;
	total: string;
	saveAsDraft: string;
	createCostModal: string;
	loadMore: string;
	matfoxxOffersFormHeader: string;
	fileHasBeenUploaded: string;
	enterTheOfferText: string;
	enterValue: string;
	text: string;
	link: string;
	jokes: string;
	enterJoke: string;
	listOfJokes: string;
	jokeOfTheDay: string;
	offerOfTheDay: string;
	heading: string;
	entry: string;
	listOfAnnouncements: string;
	allDocuments: string;
	editPrice: string;
	howWouldYouLikeToProceed: string;
	generateDashBoardAuomatic: string;
	customerServiceAssistence: string;
	createYourOwnDashboard: string;
	balancedDashboard: string;
	graphicalDashboard: string;
	analyticalDashboard: string;
	next: string;
	addNewForecast: string;
	pleaseUploadFileFirst: string;
	oldPassword: string;
	newPassword: string;
	confirmNewPassword: string;
	passwordChanged: string;
	forgotPasswordMailSendMessage: string;
	sector: string;
	category: string;
	imageSources: string;
	forecastDelete: string;
	day: string;
	week: string;
	prices: string;
	logarithmicYAxis: string;
	informations: string;
	frequency: string;
	stockExchange: string;
	dataAvailability: string;
	addInfo: string;
	addedOn: string;
	endedOn: string;
	graphicDownload: string;
	adminDownloadForecast: string;
	default: string;
	inventoryChart: string;
	forwardCurve: string;
	uploadActiveSVG: string;
	uploadInActiveSVG: string;
	tenders: string;
	saveTenders: string;
	projectEarlyInformation: string;
	countries: string;
	noTenderArticleFound: string;
	created: string;
	validFrom: string;
	validTo: string;
	deadLine: string;
	financing: string;
	projectName: string;
	referenceNr: string;
	gtaiId: string;
	latestTenders: string;
	tenderAlerts: string;
	tenderFilterKeywords: string;
	attachment: string;
	noSavedTenders: string;
	chartSettings: string;
	right: string;
	left: string;
	color: string;
	indicator: string;
	history: string;
	axis: string;
	short: string;
	long: string;
	advancedSettings: string;
	signal: string;
	duplicateChartTooltipMessage: string;
	defaultTooltipMessage: string;
	indicatorTooltipMessage: string;
	historyTooltipMessage: string;
	axisTooltipMessage: string;
	emaToolTipMessage: string;
	smaToolTipMessage: string;
	stoToolTipMessage: string;
	macdToolTipMessage: string;
	rsiToolTipMessage: string;
	bbToolTipMessage: string;
	tmaToolTipMessage: string;
	wmaToolTipMessage: string;
	atrToolTipMessage: string;
	changeToolTipMessage: string;
	LogarithmicYAxisTooltipMessage: string;
	exportFormat: string;
	grouping: string;
	sorting: string;
	allData: string;
	intraDay: string;
	yearly: string;
	chartUpdateYearly: string;
	value: string;
	mustBeNumber: string;
	numberTableHeader: string;
	shortCustom: string;
	standard: string;
	advanced: string;
	ascending: string;
	descending: string;
	standardInfo: string;
	advancedInfo: string;
	allDataInfo: string;
	intradayInfo: string;
	weeklyInfo: string;
	monthlyInfo: string;
	ascendingInfo: string;
	descendingInfo: string;
	bar: string;
	barChartErrorMessage: string;
	barChartNumberValidationMessage: string;
	generatingChartImages: string;
	intraDayInfo: string;
	years: string;
	none: string;
	dataNotIncludedInSubscription: string;
	pleaseSelectAllHistoryDropdown: string;
	noEnoughDataToPlot: string;
	createNewGroup: string;
	groupManagement: string;
	addNewGroup: string;
	promotionCode: string;
	selectUser: string;
	selectBackpack: string;
	selectWhiteLabel: string;
	groups: string;
	editGroup: string;
	customMailCommunication: string;
	adjustMailTemplate: string;
	chooseUser: string;
	fileUploadSuccessMessage: string;
	noWhiteLabelFoundErrorMessage: string;
	phone: string;
	registration: string;
	projectAlerts: string;
	financers: string;
	deleteUser: string;
	deleteUserMessage: string;
	deleteMyMaterialConfirmationMessage: string;
	deleteMyMaterialListEntriesConfirmationMessage: string;
	groupName50CharacterValidation: string;
	groupNameWhiteSpaceValidationMessage: string;
	userDashboard: string;
	dashboardAssignSuccessfully: string;
	myMaterialListTransferredSuccessfully: string;
	transfer: string;
	acceptAtmax10Numbers: string;
	lastNameCharacterLengthValidation: string;
	firstNameCharacterLengthValidation: string;
	germanNameCharacterLengthValidation: string;
	englishNameCharacterLengthValidation: string;
	reports: string;
	allReports: string;
	matflixxForecast: string;
	allForecasts: string;
	clearAll: string;
	matflixxSectors: string;
	deleteBackPackMessage: string;
	moveWidgetHere: string;
	imageValidationMessage: string;
	imageFormateValidationMessage: string;
	germanName: string;
	englishName: string;
	activeIcon: string;
	inActiveIcon: string;
	customMaterialStructure: string;
	customNewsStructures: string;
	customForecastStructure: string;
	activePlusOriginal: string;
	hideAll: string;
	inActivityMessage: string;
	otherDeviceLoginMessage: string;
	actualData: string;
	deleteBackpack: string;
	chooseMasterData: string;
	chooseSource: string;
	enterSourceName: string;
	exportVisibleMaterialOnly: string;
	alsoExportFuturesContracts: string;
	exportExcel: string;
	exportCsv: string;
	lastLogin: string;
	devices: string;
	minutes: string;
	dailyActiveUsers: string;
	monthlyActiveUsers: string;
	weeklyActiveUsers: string;
	totalUsers: string;
	newUsers: string;
	averagePageVisits: string;
	averageTimeSpend: string;
	downloadFilesheet: string;
	currencies: string;
	units: string;
	countriesAndRegions: string;
	notations: string;
	tendersCountries: string;
}

const localizationConstants = {} as Translation;

for (const key in en) {
	localizationConstants[key as typeOf] = key;
}

export type typeOf = 'dashboard';

export default localizationConstants;
