import { Translation } from './constants';

export const de: Translation = {
	registered: 'Registered',
	customerNr: 'Kunden Nr.',
	freemium: 'Freemium',
	customer: 'Kunden',
	animateWidgetLine: 'Animierte Dashbaord Widgets',
	showPartnerLinkLine: 'Show Partner Section in Nav Bar',
	animation: 'Animationen',
	personalize: 'Personalization',
	searchMaterialEtc: 'Suche nach Preisen',
	searchNewsEtc: 'Suche nach Nachrichten',
	noTendersSaved: 'Keine Ausschreibungen gespeichert',
	noEarlyProjectInfoSaved: 'Keine Projektfrühinformationen gespeichert',
	tendersAndMore: 'Tenders & more',
	frozen: 'Frozen',
	unfreeze: 'Unfreeze',
	freeze: 'Freeze',
	adminDownloadIntraday: 'Admin Download (Intr.)',
	passwordConfirmation: 'Eine Passwortbestätigung',
	clickHereTo: 'Klicken Sie hier, um sich zu',
	forgotPasswordWaitMessage:
		'Wenn Ihre E-Mail-Adresse bekannt ist, erhalten Sie in den nächsten Minuten eine E-Mail von uns. Sollten Sie nach 5 Minuten keine E-Mail erhalten haben, prüfen Sie bitte auch Ihren Spam-Ordner. Alternativ können Sie sich auch an den Kundenservice wenden',
	oldPasswordIsRequired: 'Altes Passwort ist erforderlich',
	passwordMatch: 'Neues Passwort und neues Passwort bestätigen stimmen nicht überein!',
	spaceNotExists: 'Weltraum sollte nicht existieren',
	passwordCriteria: 'Ihr Passwort muss folgende Kriterien erfüllen:',
	oneSpecialChar: 'Mindestens ein Sonderzeichen',
	oneNumberValidation: 'Mindestens eine Zahl',
	oneUppercaseOneLowercase: 'Mindestens einen Groß und Kleinbuchstaben',
	atLeast8Characters: 'Mindestens 8 und maximal 24 Zeichen lang',
	matfoxxSettings: 'Matfoxx Einstellungen',
	matfoxxNotifications: 'Benachrichtigungen',
	matfoxxNotificationOff: 'Aus',
	matfoxxNotificationOn: 'An',
	notificationPeriod: 'Benachrichtiungsintervall',
	notificationAutomatically: 'Automatisch',
	until: 'Bis',
	filterNotifications: 'Benachrichtigunsfilter',
	quoteOTheDay: 'Zitat des Tages',
	matflixxOffers: 'Matflixx Angebote',
	updateGroup: 'Gruppe aktualisieren',
	subGroup: 'Untergruppe',
	deleteCourseList: 'Sind Sie sicher, dass Sie dieses Objekt löschen möchten?',
	createGroup: 'Daten verwalten',
	editModelParameters: 'Edit model parameters',
	addToCostModal: 'Add to Cost Modal',
	matflixxModals: 'Matflixx models',
	addMatflixxModals: 'Add Matflixx models',
	customizeCostModal: 'Kostenmodell anpassen',
	sinceOneQuater: 'Seit 1 Quartal',
	sinceOneYear: 'Seit 1 Jahr',
	sinceOneMonth: 'Seit 1 Monat',
	priceDriver: 'Preistreiber',
	shares: 'Anteile',
	share: 'Teilen',
	updateOnDashboard: 'Auf Dashboard aktualisieren',
	myCostModal: 'Mein Kostenmodell',
	dashboard: 'Dashboard',
	myDashboard: 'Mein Dashboard',
	noUserFound: 'Keine Benutzerdaten gefunden',
	customize: 'Anpassen',
	favorites: 'Favoriten',
	myData: 'Meine Daten',
	myNewsAndForecast: 'Meine Nachrichten und Prognosen',
	designation: 'Bezeichnung',
	news: 'Nachrichten',
	update: 'Aktualisierung',
	delete: 'Löschen',
	edit: 'Bearbeiten',
	date: 'Datum',
	price: 'Preis',
	change: '%-Veränderung',
	convert: 'Umrechnen',
	addToDashboard: 'Zum Dashboard hinzufügen',
	myShortcut: 'Meine Shortcuts',
	addShortcut: 'Neuen Shortcut hinzufügen',
	pleseSelectFieldMessage: 'Bitte füllen Sie dieses Feld aus',
	targetUrl: 'Ziel-URL',
	enterValidUrlAddress: 'Gültige URL-Adresse eingeben',
	urlForLogoPriview: 'URL für Logo oder Vorschau (optional)',
	rawMaterials: 'Rohstoffe',
	selectedMaterials: 'Ausgewählte Rohstoffe',
	selectedNews: 'Ausgewählte Nachrichten',
	addWidgetToDashboard: 'Widget zum Dashboard hinzufügen',
	submit: 'Speichern',
	showAll: 'Alle anzeigen',
	latestNews: 'Neueste Nachrichten',
	addWidget: 'Widget hinzufügen',
	allSectors: 'Alle Sektoren',
	collapsePanel: 'Panel ausblenden',
	noCourseListing: 'Keine Kurslisten',
	noMaterialFound: 'Keine Kurse gefunden',
	exportCorseListAsExcel: 'Kursliste als Excel exportieren',
	advanceSearchForCourses: 'Erweitere Suche nach Kursen',
	seek: 'Suche',
	searchPatterns: 'Bitte einen Suchbegriff eingeben!',
	advanceSearchPlaceHolder: 'Min. 3 Zeichen, wie z.B. “Strom”',
	duration: 'Laufzeit',
	doNotMetter: 'Keine Relevanz',
	withoutDueDate: 'Ohne Fälligkeit',
	withoutFutures: 'Ohne Futures',
	futurePerptualContracsOnly: 'Nur Future-Endlos-Kontrakte',
	futureWithoutEndlessContract: 'Futures ohne Endlos-Kontrakte',
	back: 'Zurück',
	materials: 'Materialien',
	addFilterToDashboard: 'Filter zum Dashboard hinzufügen',
	chooseDateRange: 'Datumsbereich wählen',
	cancel: 'Abbrechen',
	searchNews: 'Nachrichten durchsuchen',
	searchNewsMaterialEtc: 'Suche nach Nachrichten, Preisen, etc.',
	all: 'Alle',
	noEntriesFound: 'Keine Einträge gefunden',
	tryUsingDiffKeyword: 'Versuchen Sie ein anderes Schlagwort',
	more: 'Mehr...',
	notification: 'Benachrichtigung',
	profile: 'Profil',
	logout: 'Abmelden',
	login: 'Anmelden',
	emailAddress: 'E-Mail-Adresse',
	password: 'Passwort',
	exportHistoryAsCSV: 'Als CSV exportieren',
	exportDataAsExcel: 'Als Excel exportieren',
	add: 'Hinzufügen',
	maturityOverview: 'Laufzeiten-Übersicht',
	exportPriceAsExcel: 'Kurse als Excel exportieren',
	addWidgetToScreeen: 'Zum Dashboard hinzufügen',
	setUpPriceAlert: 'Kurs-Alert einrichten',
	addChartToDashboard: 'Chart zum Dashboard hinzufügen',
	courseListings: 'Kurslisten',
	renameCourseList: 'Kursliste umbenennen',
	rename: 'Umbenennen',
	extinguish: 'Löschen',
	newsFilterTags: 'Nachrichten Filter / Schlagwörter',
	alerts: 'Alerts',
	notifyAtTime: 'Benachrichtigen um ... Uhrzeit (optional)',
	noteMessageForAllKeywordAppears: 'Hinweis: Die Benachrichtigung erfolgt nur, wenn alle Schlagwörter in einer Nachricht vorkommen!',
	addNews: 'Neuer Filter',
	currnetCourse: 'Aktueller Kurs',
	reportIf: 'Melden wenn',
	courseExceeded: 'Kurs überschritten',
	courseUndershot: 'Kurs unterschritten',
	absPerDeviationExceeded: 'abs. perc. deviation d.K. exceeded',
	comparativeValue: 'Vergleichswert',
	dateOfExpiry: 'Auslaufdatum',
	addCourseAlert: 'Kurs-Alert hinzufügen',
	messageAlerts: 'Mitteilungsalarm',
	keywords: 'Schlagwörter',
	exmpleEleGas: 'z.B. “Strom"',
	forecasts: 'Prognosen',
	name: 'Name',
	noNewsHeading: 'Keine Nachrichtenrubriken...',
	createNewPriceList: 'Eine neue Preisliste erstellen',
	addAnotherOneLanguage: 'Eine weitere Sprache hinzufügen',
	view: 'Anzeigen',
	uploadFile: 'Datei hochladen',
	maturity: 'Fälligkeit',
	symbol: 'Symbol',
	visible: 'Sichtbar',
	notVisible: 'Nicht sichtbar',
	pricesTime: 'Kurszeit',
	selectAll: 'Alles auswählen',
	hideSelected: 'Ausgewählte ausblenden',
	deleteSelected: 'Ausgewählte löschen',
	editUserData: 'Benutzerdaten bearbeiten',
	save: 'Speichern',
	generalInfo: 'Allgemeine Informationen',
	salutation: 'Anrede',
	mr: 'Herr',
	ms: 'Frau',
	surname: 'Nachname',
	company: 'Unternehmen',
	contacts: 'Kontakte',
	phoneNumber: 'Rufnummer',
	email: 'E-mail',
	permissionData: 'Erlaubnis-Daten',
	status: 'Status',
	active: 'Aktiv',
	admin: 'Admin',
	inactive: 'Inaktiv',
	validUntill: 'Gültig bis',
	apiInterfaceActive: 'API-Schnittstelle aktiv',
	apiKey: 'API-key',
	comment: 'Kommentar',
	superAdminMessage: 'Super-Admin',
	// , d.h. darf Admins und besondere Tabellen ändern.',
	adminMessage: 'User Admin',
	// , d.h. darf Benutzer und Benutzerrechte ändern, sowie alles was Bearbeiter/Redakteur dürfen.',
	allMaterials: 'Alle Materialien',
	myMaterials: 'Meine Materialien',
	search: 'Suche',
	saveMessage: 'Gespeicherte Nachrichten',
	constModels: 'Kostenmodelle',
	courses: 'Kurs',
	dataProcessorMessage: 'Datenbearbeiter',
	// , d.h. berechtigt, Kurse, Historien und MBI-Kurslisten zu ändern',
	editorMessage: 'Redakteur',
	// , d.h. darf Nachrichten ändern und löschen',
	API: 'API',
	statistics: 'Statistiken',
	validity: 'Gültigkeit',
	markant: 'Markant',
	manageData: 'Daten verwalten',
	permission: 'Berechtigungen',
	ownDataUpload: 'Eigene Daten hochladen',
	invisible: 'Unsichtbar',
	whiteLabelAdmin: 'Whitelabel-Benutzer',
	groupName: 'Gruppenname',
	files: 'Dateien',
	addFile: 'Datei hinzufügen',
	dragAndDropToUploadFile: 'Drag & Drop zum Hochladen von Dateien',
	uploaded: 'Hochgeladen',
	timeLimit: 'Zeitlimit',
	occurred: 'Aufgetreten',
	deactive: 'Deaktivieren',
	isin: 'ISIN',
	exchange: 'Börsenname',
	dataProvider: 'Datenlieferant',
	source: 'Quelle',
	cpCustomer: 'CP Kunde',
	courseTime: 'Kurszeit',
	country: 'Land',
	currency: 'Währung',
	pleaseSelectACategory: 'Bitte wählen Sie eine Kategorie aus',
	chooseCountry: 'Land wählen',
	runTime: 'Laufzeit',
	chooseCurrency: 'Währung wählen',
	advanceSearchfForPrice: 'Erweitere Suche nach Kursen',
	advanceSearchTableText:
		'Zu Ihrer Suche sind noch mehr passende Kurse vorhanden. Blättern Sie vorwärts. Insgesamt werden jedoch maximal 200 Ergebnisse angezeigt.',
	advanceSearchTableDescription:
		'Geben Sie mehr Suchwörter ein, um das Ergebnis zu verfeinern und die Suche einzuschränken. Nutzen Sie auch Abkürzungen oder DIN / AISI Codes. Variieren Sie zwischen der deutschen und englischen Bezeichnung. Oder stellen Sie Wörtern ein Minus voran, z.B. -Deutschland',
	tip: 'Tipp',
	line: 'Linien',
	area: 'Flächen',
	candle: 'Kerzen',
	chartType: 'Charttyp',
	indicators: 'Indikatoren',
	span: 'Zeitraum',
	addToPrice: ' Zu meinen Materialien hinzufügen',
	currentPrice: 'Derzeitiger Preis',
	alertCurrency: 'Währung (Original)',
	addedToDashboard: 'Zum Dashboard hinzugefügt',
	updatedToDashboard: 'Auf Dashboard aktualisiert!',
	exportData: 'Datenexport',
	selectFileFormat: 'Dateityp auswählen',
	users: 'Benutzer',
	data: 'Daten',
	whiteLabel: 'White Label',
	matfoxx: 'Matfoxx',
	myMaterialsEmptyTitle: 'Diese Kursliste ist leer!',
	myMaterialEmptyDescription:
		'Um Materialien oder Preise zu dieser Kursliste hinzuzufügen, bitte ein Material/Preis über die Suchfunktion oder über Alle Materialien öffnen und dann auf "Zu einer Kursliste hinzufügen" klicken. Dann den Namen diese Kursliste auswählen.',
	advancedSearchResult: 'Die Suche liefert keine Ergebnisse.',
	profileData: 'Profil & Daten',
	profileInfo: 'Profil-Information',

	profileSaveChanges: 'Änderungen speichern',
	profilePhoto: 'Profilfoto',
	profileAddFile: 'Datei hinzufügen',
	profileDragandDrop: 'oder per Drag & Drop hierher ziehen',
	profileSavePhotos: 'Foto speichern',
	profileDeletePhotos: 'Foto löschen',
	profileGeneralData: 'Allgemeine Daten',
	profileCurrentEmail: 'Aktuelle E-Mail-Adresse',
	profileChangePassword: 'Passwort ändern',
	advancedSearchTip:
		'Tipp: Geben Sie mehr Suchwörter ein, um das Ergebnis zu verfeinern und die Suche einzuschränken. Nutzen Sie auch Abkürzungen oder DIN / AISI Codes. Variieren Sie zwischen der deutschen und englischen Bezeichnung. Oder stellen Sie Wörtern ein Minus voran, z.B. -Deutschland, um Preisdaten auszuschließen, die dieses Wort im Namen haben.',
	adminDownloadHistory: 'Admin-Download (Hist.)',
	addToMbiList: 'Zu einer MBI-Kursliste hinzufügen',
	assignMaterialToUser: 'Material einem Benutzer zuweisen',
	dataEditing: 'Datenbearbeitung',
	addCourseToCourseList: 'Kurs zu einer Kursliste hinzufügen',
	searchUsers: 'Benutzer suchen',
	general: 'Allgemein',
	registerNow: 'Registrieren',
	city: 'Stadt',
	position: 'Position',
	postcode: 'Postleitzahl',
	required: 'Erforderlich',
	firstname: 'Vorname',
	download: 'Download',
	imprint: 'Impressum',
	settings: 'Einstellungen',
	abort: 'Abbrechen',
	takeOver: 'Übernehmen',
	removed: 'Entfernen',
	days: 'Tage',
	setPeriod: 'Zeitraum festlegen',
	onboardSecondTitle: 'Welche Darstellungsweise bevorzugen Sie?',
	createPassword: 'Passwort erstellen',
	futures: 'Zukunft',
	masterExportExcel: 'Stammdaten-Download',
	enterValuesGreaterThan: 'Eingabe Wert größer als',
	enterValuesLessThan: 'Eingabe Wert kleiner als',
	comparisonValues: 'Vergleichswert muss zwischen 0 - 100 liegen',
	comparisonValueGreaterThanZero: 'Vergleichswert muss größer als Null sein',
	numberIsTooBig: 'Nummer ist zu groß',
	courseDifference: 'Abs. proz. Abweichung d. K. überschritten',
	selectFromTheDropDown: 'Bitte aus dem Dropdown-Menü auswählen',
	title: 'Bezeichnung',
	url: 'URL',
	urlplaceholder: 'https://...',
	registrationValidationType: 'Unzulässiger Typ',
	addShortCutDeleteLink: 'Link löschen',
	comparativeSearchPlaceholder: 'Vergleichswert',
	month: 'Monat',
	months: 'Monate',
	year: 'Jahr',
	custom: 'Benutzerdefiniert',
	emptyChartMessage: 'Daten konnten leider nicht geladen werden, weil der letzte Kurs älter als ein Jahr ist!',
	ema: 'Exponentieller gleitender Durchschnitt (EMA)',
	sma: 'Einfacher gleitender Durchschnitt (SMA)',
	bb: 'Bollinger Bänder (BB)',
	tma: 'Triangularer gleitender Durchschnitt (TMA)',
	wma: 'Gewichteter gleitender Durchschnitt (WMA)',
	atr: 'Wahre durchschnittliche Schwankungsbreite (ATR)',
	rsi: 'Relative Stärke Index (RSI)',
	macd: 'Konvergenter / Divergenter gleitender Durchschnitt (MACD)',
	sto: 'Stochastischer Oszillator (STO)',
	lowDataChartMessage: 'Keine oder zu wenig Kurse!',
	choose: 'wählen',
	noAlertFound: 'Keine Alerts gefunden',
	loadingChartData: 'Chartdaten werden geladen...',
	deleteDashboardWidget: 'Sicher, dass Sie dieses Widget löschen möchten?',
	positiveDifference: '% positive Abweichung',
	negativeDifference: '% negative Abweichung',
	updateChartToDashboard: 'Chart auf Dashboard aktualisieren',
	daily: 'Täglich',
	weekly: 'Wöchentlich',
	monthly: 'Monatlich',
	updateToDashBoard: 'Auf Dashboard aktualisieren',
	addMaterials: 'Materialien Hinzufügen',
	addNewsAndForecast: 'Nachrichten und Prognosen hinzufügen',
	unlimited: 'Unbegrenzt',
	markAllAsRead: 'Markiere alle als gelesen',
	otherChanges: 'Andere Änderungen',
	materialAlert: 'Kurs-Alerts',
	newslAlert: 'Nachrichten-Alerts',
	actual: 'Aktuell',
	hist: 'Historisch',
	high: 'Hoch',
	low: 'Tief',
	histDate: 'Hist. Datum',
	unit: 'Einheit',
	kurszeit: 'Kurszeit',
	dueDate: 'Fälligkeit',
	mbiListPlaceHolder: 'Bez. eingeben',
	isRequired: 'ist erforderlich!',
	confirmLogout: 'Sind Sie sicher, dass Sie sich abmelden möchten?',
	yes: 'Ja',
	no: 'Nein',
	faq: 'FAQ',
	almostThere: 'Almost there!',
	weNeedInfo: 'We just need a little more information.',
	bis: 'Bis',
	von: 'Von',
	protocol: 'Protokoll',
	categoryIncludeMessage: 'Dieses Material ist in folgenden Kurslisten enthalten :',
	userIsAdminMessage: 'Der Benutzer {{userName}} ist ein Administrator und kann alle Daten sehen, weshalb hier nichts angezeigt wird',
	editNews: 'Nachrichten bearbeiten',
	deleteNews: 'Nachrichten löschen',
	adminPriceDownload: 'Admin-Kurs-Download',
	userId: 'Benutzer-ID',
	uploadedBy: 'Hochgeladen von',
	fileName: 'Dateiname',
	inserted: 'Eingefügt',
	updated: 'Aktualisiert',
	processes: 'Verarbeitet',
	mistake: 'Fehler',
	noGroup: 'Keine Gruppe',
	enterEmail: 'Bitte eine korrekte Email-Adresse angeben',
	forgotPasswordNote:
		'Wenn Sie ein neues Passwort anfordern, erhalten Sie von uns eine E-Mail mit einem Link zum Einrichten eines neuen Passworts.',
	requestNewPassword: 'Neues Passwort anfordern',
	alreadyRegistered: 'Wenn Sie bereits registriert sind, melden Sie sich hier an',
	here: 'hier',
	notSubscribeYet: 'Sie sind noch kein Abonnent? Dann',
	register: 'registrieren',
	now: 'jetzt',
	passwordForgotten: 'Passwort vergessen',
	testForFree: 'und testen Sie Matflixx kostenlos.',
	noSavedNewsFound: 'Sie haben keine Nachrichten markiert...',
	takeOverAccount: 'Übernehmen',
	takeOverFirstMessage:
		'Sie haben dieses Benutzerkonto übernommen. Änderungen, die Sie vornehmen, wirken sich tatsächlich auf das Kundenkonto aus. Klicken Sie',
	takeOverSecondMessage: ', um zu Ihrem Konto zurückzukehren.',
	saveChanges: 'Änderungen speichern',
	discard: 'Verwerfen',
	lastName: 'Nachname',
	removeFromDashboard: 'Vom Dashboard entfernt',
	newsSections: 'Nachrichtenrubriken...',
	itemChoosen: 'Elemente gewählt.',
	group: 'Gruppe',
	authorized: 'Berechtigung',
	mainCourse: 'Hauptkurs',
	alertWhen: 'Melden wenn',
	entered: 'Eingetreten',
	newsFilterKeyWords: 'Nachrichtenfilter / Schlagwörter',
	deleteAlertMessage: 'Möchten Sie die Kurs-Überwachnung für {{alert}} löschen?',
	visibility: 'Sichtbarkeit',
	hidden: 'Verborgen',
	addPartnersLink: 'Partnerlink hinzufügen',
	create: 'Erstellen...',
	savedNews: 'Nachricht gespeichert.',
	shortcutCreated: 'Shortcut hinzugefügt.',
	shortcutUpdated: 'Änderungen gespeichert.',
	shortcutDeleted: 'Shortcut entfernt.',
	widgetSwiped: 'Die Elementenanordnung wurde angepasst.',
	grantPermissionToUser: 'Die Benutzerrechte wurden aktualisiert!',
	deleteNewsArticle: 'Artikel wurde gelöscht.',
	deleteFromCourseList: 'Eintrag aus der Kursliste entfernt!',
	addedToList: 'Zur Liste hinzugefügt!',
	assign: 'Zuweisen',
	forgotPassword: 'Passwort vergessen',
	priceListIsEmpty: 'Diese Kursliste ist leer!',
	emptyPriceListMessage:
		'Um Materialien oder Preise zu dieser Kursliste hinzuzufügen, bitte ein Material/Preis über die Suchfunktion oder über Alle Materialien öffnen und dann auf "Zu einer Kursliste hinzufügen" klicken. Dann den Namen diese Kursliste auswählen.',
	deleteNewsPopupMessage: 'Sind Sie sicher, dass Sie den Artikel löschen möchten?',
	validUrlErrorMessage: 'Please enter valid URL',
	addInformation: 'Information hinzufügen',
	enterNote: 'Notiz eingeben',
	choosePriority: 'Priorität wählen',
	normal: 'Normal',
	critical: 'Kritisch',
	editInformation: 'Bearbeiten information',
	authorizationElement: 'Autorisierungselement',
	userList: 'Benutzerliste',
	masterData: 'Stammdaten',
	backpack: 'Rucksack',
	createNewBackpack: 'Erstellen Sie einen neuen Rucksack',
	editBackpack: 'Rucksack bearbeiten',
	reset: 'Zurücksetzen',
	whiteLabelFormTitle: 'Ändern Sie die Farbe der Benutzeroberfläche',
	colors: 'Farben',
	courseListNameHasBeenSaved: 'Kurslistename wurde gespeichert!',
	courseListDeleted: 'Kursliste gelöscht!',
	noCurrentData: 'Keine aktuellen Daten',
	maximumLimitReached: 'Limit erreicht. Einige Elemente konnten nicht zum Dashboard hinzugefügt werden',
	noNewsArticleFound: 'Keine Nachrichten gefunden...',
	downloadMasterData: 'Stammdaten-Download',
	comparisionValueShouldNotBeEmpty: 'Der Vergleichswert darf nicht leer sein',
	additionalInfo: 'Zusatzinfo',
	chartUpdateDaily: 'Täglich',
	chartUpdateWeekly: 'Wöchentlich',
	chartUpdateMonthly: 'Monatlich',
	listName: 'Listenname',
	partnerLinks: 'Partner-Links',
	addAlertDropDownPlaceholder: 'Auswählen',
	glossary: 'Glossar',
	normalization: 'Normalisieren',
	upload: 'Hochladen',
	listofOffers: 'Angebotsliste',
	myModels: 'Meine Modelle',
	exportAsExcel: 'Daten als Excel exportieren',
	exportAsCSV: 'Daten als CSV exportieren',
	createNewCostModal: 'Neues Kostenmodell erstellen',
	noDataFound: 'Keine Daten gefunden',
	modalName: 'Modellname',
	sectors: 'Sektoren',
	makeThisModelVisible: 'Dieses Modell sichtbar machen',
	ingridients: 'Bestandteile',
	number: 'Nr.',
	displayName: 'Komponentenname',
	amount: 'Menge',
	ratio: 'Anteil',
	total: 'Gesamt',
	saveAsDraft: 'Als Entwurf speichern',
	createCostModal: 'Kostenmodell erstellen',
	addNewsDashboard: 'Nachrichten hinzufügen',
	loadMore: 'Mehr laden',
	matfoxxOffersFormHeader: 'Angezeigter Text, Handlung, Dauer',
	fileHasBeenUploaded: 'Datei wurde hochgeladen',
	enterTheOfferText: 'Angebotstext einfügen',
	enterValue: 'Wert eingeben',
	text: 'Text',
	link: 'Verknüpfung',
	jokes: 'Witze',
	enterJoke: 'Einen Witz eingeben',
	listOfJokes: 'Witzliste',
	jokeOfTheDay: 'Witz des Tages',
	offerOfTheDay: 'Angebot des Tages',
	heading: 'Überschrift',
	entry: 'Eintrag',
	listOfAnnouncements: 'Liste der Ankündigungen',
	allDocuments: 'Alle Dokumente',
	editPrice: 'Preis Bearbeiten',
	howWouldYouLikeToProceed: 'Wie möchten Sie fortfahren?',
	generateDashBoardAuomatic: 'Dashboard automatisch erstellen',
	customerServiceAssistence: 'Kundenservice kontaktieren',
	createYourOwnDashboard: 'Eigenes Dashboard erstellen',
	balancedDashboard: 'Ausgeglichenes Dashboard',
	graphicalDashboard: 'Graphisches Dashboard',
	analyticalDashboard: 'Analytisches Dashboard',
	next: 'Weiter',
	addNewForecast: 'Eine neue Prognose hinzufügen',
	pleaseUploadFileFirst: 'Bitte zunächst Datei hochladen*',
	oldPassword: 'Altes Passwort',
	newPassword: 'Neues Passwort',
	confirmNewPassword: 'Bestätigen Neues Passwort',
	passwordChanged: 'Ändern passwort',
	forgotPasswordMailSendMessage: 'Wir senden Ihnen innerhalb weniger Minuten eine E-Mail.',
	sector: 'Sektor',
	category: 'Kategorie',
	imageSources: 'Bildquellen',
	chartUpdateQuarterly: 'Quartalweise',
	forecastDelete: 'Prognose gelöscht!',
	day: 'Tag',
	week: 'Woche',
	prices: 'Preise',
	logarithmicYAxis: 'Logarithmische Y-Achse',
	informations: 'Informationen',
	frequency: 'Frequenz',
	stockExchange: 'Börse',
	dataAvailability: 'Datenverfügbarkeit',
	addInfo: 'Zus. Informationen',
	addedOn: 'Hinzugefügt am',
	endedOn: 'Eingestellt am',
	graphicDownload: 'Grafik exportieren',
	adminDownloadForecast: 'Admin download (Forecast)',
	default: 'Standard',
	inventoryChart: 'Lagerbestand',
	forwardCurve: 'Forwardkurve',
	uploadActiveSVG: 'Upload aktives SVG-Icon',
	uploadInActiveSVG: 'Upload inaktives SVG-Icon',
	tenders: 'Ausschreibungen',
	saveTenders: 'Gespeicherte Tenders',
	projectEarlyInformation: 'Projektfrühinformationen',
	countries: 'Länder',
	noTenderArticleFound: 'Keine Ausschreibungen gefunden',
	created: 'Erscheinungsdatum',
	validFrom: 'Gültig von',
	validTo: 'Gültig bis',
	deadLine: 'Deadline',
	financing: 'Finanzierung',
	gtaiId: 'Gtai-ID',
	projectName: 'Betreff',
	referenceNr: 'Referenznummer',
	latestTenders: 'Neueste Ausschreibungen',
	tenderAlerts: 'Ausschreibungen-Alerts',
	tenderFilterKeywords: 'Ausschreibung Filter / Schlagwörter',
	attachment: 'Anhänge',
	noSavedTenders: 'Sie haben keine Ausschreibungen gespeichert',
	chartSettings: 'Chart-Einstellungen',
	left: 'Links',
	right: 'Rechts',
	color: 'Farbe',
	indicator: 'Indikator',
	history: 'Historie',
	axis: 'Achse',
	short: 'Kurz',
	long: 'Lang',
	advancedSettings: 'Erweiterte Einstellungen',
	signal: 'Signal',
	duplicateChartTooltipMessage:
		'Dupliziert den Kurs, um historische Vergleiche im Jahreszeitraum oder Vergleiche in verschiedenen Währungen mit dem bereits ausgewählten Material/Datensatz durchzuführen.	',
	defaultTooltipMessage: 'Charting der Preisdaten',
	indicatorTooltipMessage: 'Auswahl der anzuwendenden Indikatoren. Es können mehrere Indikatoren gleichzeitig angewendet werden.',
	axisTooltipMessage: 'Position der Y-Achse',
	historyTooltipMessage: 'Auswahl der Historie nach Jahr',
	emaToolTipMessage: 'Durchschnittspreis eines Vermögenswerts, neue und aktuelle Daten/Preise haben größeres Gewicht.',
	smaToolTipMessage: 'Durchschnittspreis eines Vermögenswerts/Wertpapiers über einen bestimmten Zeitraum.',
	stoToolTipMessage: 'Ein Schlusskurs eines Wertpapiers wird mit einer Spanne seiner Kurse über einen bestimmten Zeitraum verglichen.',
	macdToolTipMessage: 'Ein Indikator zum Verständnis der Dynamik und Richtungsstärke eines Wertpapiers.',
	rsiToolTipMessage: 'Misst die Geschwindigkeit und Veränderung von Kursbewegungen.',
	bbToolTipMessage: 'Instrument der technischen Analyse.',
	tmaToolTipMessage: 'Der Durchschnitt des SMA. Reagiert nicht schnell auf volatile Marktbedingungen.',
	wmaToolTipMessage: 'Weist den aktuellen Datenpunkten ein größeres Gewicht zu.',
	atrToolTipMessage: 'Wird zur Messung der Kursbewegung eines Wertpapiers verwendet und basiert auf absoluten Werten.',
	changeToolTipMessage: 'Charting der Veränderung zum vorherigen Datenpunkt',
	LogarithmicYAxisTooltipMessage: 'Erlaubt die bessere Darstellung von hohen Beträgen',
	numberTableHeader: 'Kursdaten',
	shortCustom: 'benutzerdef.',
	mustBeNumber: 'muss eine Zahl sein',
	exportFormat: 'Exportformat',
	grouping: 'Gruppierung',
	sorting: 'Sortierung',
	allData: 'Alle Daten',
	intraDay: 'Intraday',
	yearly: 'Jährlich',
	chartUpdateYearly: 'Jährlich',
	standard: 'Standard',
	advanced: 'Fortgeschritten',
	ascending: 'Aufsteigend',
	descending: 'Absteigend',
	standardInfo: 'Enthält Preisdaten und Chart',
	advancedInfo: 'Standardexport erweitert um weitere Daten (falls verfügbar), wie prozentuale Veränderung und Forwards',
	allDataInfo: 'Export der täglichen Datenpunkte bzw. der Schlusskurse bei Intraday-Kursen',
	intradayInfo: 'Export aller Preisdaten innerhalb eines Handelstages',
	weeklyInfo: 'Export der wöchentlichen Datenpunkte bzw. der Wochendurchschnitte der verfügbaren Daten',
	monthlyInfo: 'Export der monatlichen Datenpunkte bzw. der Monatsdurchschnitte der verfügbaren Daten',
	ascendingInfo: 'Sortierung vom ältesten zum neusten Datenpunkt',
	descendingInfo: 'Sortierung vom neusten zum ältesten Datenpunkt',
	bar: 'Balken',
	value: 'Wert',
	barChartErrorMessage: 'Prozentuale Veränderung nur im Liniendiagramm sichtbar',
	barChartNumberValidationMessage: 'Das Balkendiagram unterstützt nur bis zu 3 Vergleichspreise',
	generatingChartImages: 'Chart-Abbildungen werden erzeugt...',
	intraDayInfo: 'Export aller Preisdaten innerhalb eines Handelstages',
	years: 'Jahre',
	noEnoughDataToPlot: 'Zu wenig Daten für grafische Darstellung.',
	createNewGroup: 'Neue Gruppe erstellen',
	none: 'Keine',
	dataNotIncludedInSubscription: 'Dieser Kurs ist aktuell nicht in Ihrem Abonnement enthalten.',
	pleaseSelectAllHistoryDropdown: 'Historien können nur mit anderen Historien verglichen werden.',
	groupManagement: 'Gruppen Verwaltung',
	addNewGroup: 'Neue Gruppe hinzufügen',
	promotionCode: 'Aktionscode',
	selectUser: 'Nutzer auswählen',
	selectBackpack: 'Rucksack auswählen',
	selectWhiteLabel: 'Whitelabel auswählen',
	groups: 'Gruppen',
	editGroup: 'Gruppenverwaltung',
	customMailCommunication: 'Individuelle Mailkommunikation',
	adjustMailTemplate: 'Mailtemplates anpassen',
	chooseUser: 'Nutzer auswählen',
	fileUploadSuccessMessage: 'Datenupload erfolgreich',
	noWhiteLabelFoundErrorMessage: 'Kein Whitelabel gefunden! Bitte klicken sie auf "+", um ein neues zu erstellen.',
	registration: 'Registrierung',
	projectAlerts: 'Projekt-Alerts',
	copyToClipboard: 'Copied to Clipboard',
	somethingWentWrong: 'Something went wrong :confused:',
	phone: 'Telefon',
	foreignTradeNews: 'Außenwirtschafts-nachrichten',
	financers: 'Finanzierer',
	deleteUser: 'Benutzer löschen',
	deleteUserMessage: 'Um einen Benutzer zu löschen, geben Sie "DELETE" ein',
	deleteMyMaterialConfirmationMessage: 'Sind Sie sicher, dass Sie den Kurs entfernen wollen?',
	deleteMyMaterialListEntriesConfirmationMessage: 'Sind Sie sicher, dass Sie die Liste löschen wollen?',
	groupName50CharacterValidation: 'Gruppenname darf höchstens 50 Zeichen haben',
	groupNameWhiteSpaceValidationMessage: 'Der Gruppenname darf nicht mehr als ein Leerzeichen zwischen den Wörtern enthalten',
	userDashboard: 'Benutzer Dashboards',
	dashboardAssignSuccessfully: 'Dashboard erfolgreich zugewiesen!',
	myMaterialListTransferredSuccessfully: '"Meine Materialien"-Liste erfolgreich übertragen!',
	transfer: 'Übertragen',
	acceptAtmax10Numbers: 'Maximal 10 Nummern akzeptiert',
	firstNameCharacterLengthValidation: 'Vorname darf höchstens 30 Zeichen lang sein',
	lastNameCharacterLengthValidation: 'Nachname darf höchstens 30 Zeichen lang sein',
	germanNameCharacterLengthValidation: 'German name must be at most 50 characters',
	englishNameCharacterLengthValidation: 'German name must be at most 50 characters',
	reports: 'Reports',
	allReports: 'All Reports',
	clearAll: 'Auswahl aufheben',
	matflixxForecast: 'Matflixx Prognosen',
	deleteBackPackMessage: 'Um einen Rucksack zu löschen, geben Sie "DELETE" ein',
	inActivityMessage: 'Sie wurden auf Grund von Inaktivität ausgeloggt. Bitte loggen Sie sich erneut ein.',
	otherDeviceLoginMessage:
		'Es scheint als verwenden Sie Matflixx auf einem anderen Gerät. Für weitere Informationen kontaktieren Sie bitte den Kundenservice.',
	allForecasts: 'Alle Prognosen',
	matflixxSectors: 'Matflixx Sektoren',
	moveWidgetHere: 'Wiedget hierhin verschieben',
	imageValidationMessage: 'Die Datei ist zu groß. Bitte wählen Sie einen Anhang, der kleiner als 1 MB ist.',
	imageFormateValidationMessage: 'Nicht unterstütztes Dateiformat. Es werden nur die Formate JPEG, JPG und PNG unterstützt.',
	germanName: 'Deutscher Name',
	englishName: 'Englischer Name',
	activeIcon: 'Aktives Icon',
	inActiveIcon: 'Inaktives Icon',
	customMaterialStructure: 'Benutzerdefinierte Material-Struktur',
	customNewsStructures: 'Benutzerdefinierte Nachrichten-Struktur',
	customForecastStructure: 'Benutzerdefinierte Forecast-Struktur',
	activePlusOriginal: 'Aktiv (+zeige Original)',
	hideAll: 'Alle verbergen',
	actualData: 'Ist-Daten',
	deleteBackpack: 'Rucksack löschen',
	chooseMasterData: 'Stammdaten auswählen',
	chooseSource: 'Quelle auswählen',
	enterSourceName: 'Quellenname eingeben',
	exportVisibleMaterialOnly: 'Nur sichtbare Materialien exportieren',
	alsoExportFuturesContracts: 'Auch Terminkontrakte exportieren',
	exportCsv: 'Als CSV exportieren',
	exportExcel: 'Als Excel exportieren',
	devices: 'Geräte',
	lastLogin: 'Letzter Log-in',
	minutes: 'Minuten',
	dailyActiveUsers: 'Tägliche aktive User',
	weeklyActiveUsers: 'Wöchentliche aktive User',
	monthlyActiveUsers: 'Monatliche Aktive User',
	totalUsers: 'User gesamt',
	newUsers: 'Neue User',
	averageTimeSpend: 'Durchschnittliche Nutzungsdauer',
	averagePageVisits: 'Durchschnittliche Seitenaufrufe',
	downloadFilesheet: 'Tabellen-Downloads',
	currencies: 'Währungen',
	units: 'Einheiten',
	countriesAndRegions: 'Länder und Regionen',
	notations: 'Notierungen',
	tendersCountries: 'Ausschreibungsländer'
};
