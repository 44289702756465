import { t } from 'i18next';
import isEmpty from 'lodash/isEmpty';
import { Component } from 'react';
import Sortable from 'sortablejs';

import Confirm from 'shared/components/confirm/confirm';
import Button from 'shared/components/form/button';
import { notify } from 'shared/components/notification/notification';
import { Loader } from 'shared/components/spinner/spinner';
import { API_CONFIG } from 'shared/constants/constants';
import { CloseSideNav, LinkIcon } from 'shared/icons/icon';
import { CardView, DashboardSection, OnboardingActions } from 'shared/interface/enums';
import authService from 'shared/services/auth.service';
import httpService from 'shared/services/http.service';
import localizationConstants from 'shared/util/translation/constants';
import { Translate } from 'shared/util/translation/translate';
import { onSwapBetweenList, scrollToTop, swapData } from 'shared/util/utility';

import { ICostModal } from 'features/costModal/interface/costModal';
import { createChartConfig } from 'features/futuretable/util/chartUtility';
import { LoginResponse } from 'features/login/interface/login';
import AddShortcutPopup from '../component/addShortcutPopup';
import AnnouncementPopup from '../component/announcemenrPopup';
import FavouriteSortableContainer from '../component/favoriteSortableContainer';
import MyShortcutSideNav from '../component/myShortcutSideNav';
import SelectProcedurePopup from '../component/selectProcedurePopup';
import {
	IAnnouncmentData,
	IDashboardData,
	IDashboardType,
	IFutureTableComponentType,
	IFutureTableParams,
	IListComponent,
	IListComponentParams,
	IMyLinks,
	INewsComponent,
	INewsParams,
	IPartnersLink
} from '../interface/dashboard';

import '../style/dashboard.scss';

interface UIState {
	loading: boolean;
	favoritesData: IDashboardData[];
	myData: IDashboardData[];
	myNewsData: IDashboardData[];
	deleteId: string;
	isOpen: boolean;
	// widgetSection: string;
	myLink: IMyLinks[];
	selectedShortcut: IMyLinks;
	isCustomMode: boolean;
	partnersLink: IPartnersLink[];
	action: string;
	userData: LoginResponse;
	actionLoading: boolean;
	announcementData: IAnnouncmentData[];
	news_widget_animation: boolean;
}

class DashboardContainer extends Component {
	state: UIState = {
		loading: true,
		favoritesData: [],
		myData: [],
		myNewsData: [],
		deleteId: '',
		isOpen: false,
		// widgetSection: '',
		myLink: [],
		selectedShortcut: {} as IMyLinks,
		isCustomMode: false,
		partnersLink: [],
		action: '',
		userData: authService.getUserData() as LoginResponse,
		actionLoading: false,
		announcementData: [],
		news_widget_animation: false
	};

	componentDidMount() {
		scrollToTop();
		if (this.state.userData.is_login) {
			scrollToTop();
			this.fetchDashboardData();
			this.fetchAnnouncements();
			this.getAnimateNewsWidget();
		} else {
			this.setState({ action: OnboardingActions.FIRST_STEP });
		}
	}

	render() {
		const { loading, favoritesData, isOpen, myLink, myData, myNewsData, isCustomMode, partnersLink, userData, news_widget_animation } =
			this.state;
		const isAnnouncement = localStorage.getItem('isAnnouncement');
		const dataMapper = [
			{
				showAddWidget: userData.whitelable_config?.material_status !== 'hideall' || userData.whitelable_config?.news_status !== 'hideall',
				showLabel: true,
				label: t(localizationConstants.favorites),
				section: 'favorite',
				items: favoritesData,
				numberOfCards: favoritesData.length < 7
			},
			{
				showAddWidget: userData.whitelable_config?.material_status !== 'hideall',
				showLabel: !(userData.whitelable_config?.material_status === 'hideall' && userData.whitelable_config?.news_status === 'hideall'),
				label:
					userData.whitelable_config?.material_status === 'hideall'
						? t(localizationConstants.myNewsAndForecast)
						: userData.whitelable_config?.news_status === 'hideall'
						? t(localizationConstants.myMaterials)
						: t(localizationConstants.myMaterials),
				section: 'myData',
				items: myData,
				numberOfCards: myData.length < 7
			},
			{
				showAddWidget: userData.whitelable_config?.news_status !== 'hideall',
				showLabel: !(userData.whitelable_config?.news_status === 'hideall' || userData.whitelable_config?.material_status === 'hideall'),
				label: t(localizationConstants.myNewsAndForecast),
				section: 'myNews',
				items: myNewsData,
				numberOfCards: myNewsData.length < 7
			}
		];

		return (
			<div className='dashboard-container full-height' id='dashboard-scrollable--wrapper'>
				{Number(isAnnouncement) === 1 && !isEmpty(this.state.announcementData) && (
					<AnnouncementPopup announcementData={this.state.announcementData} />
				)}
				{!this.state.userData.is_login &&
					(this.state.action === OnboardingActions.FIRST_STEP || this.state.action === OnboardingActions.SECOND_STEP) && (
						<SelectProcedurePopup
							handleModalState={this.handleModalState}
							getDashboardData={this.fetchDashboardData}
							toggleLoginFlag={this.toggleLoginFlag}
							action={this.state.action}
						/>
					)}
				{isOpen && (
					<Confirm
						message={`${t(localizationConstants.deleteDashboardWidget)}`}
						handleClose={() => this.setState({ isOpen: !isOpen })}
						handleConfirm={this.handleConfirmDelete}
						show={isOpen}
						loading={this.state.actionLoading}
					/>
				)}
				<div className={`side-links z-index--7 position--fixed ${this.state.action === 'openShortcut' ? 'hide' : ''}`}>
					<Button
						onClick={() => this.handleModalState('openShortcut')}
						className='btn btn-orange d-flex justify-content-center align-items-center no-border'
						btnType='primary'
					>
						<LinkIcon />
					</Button>
				</div>
				{/* {this.state.action === 'addWidget' && (
					<AddWidgetPopup
						favoritesCardLength={this.state.favoritesData.length}
						newsCardLength={this.state.myNewsData.length}
						widgetSection={this.state.widgetSection}
						materialCardLength={this.state.myData.length}
						isLogin={this.state.userData.is_login}
						handleModalState={this.handleModalState}
						fetchDashboardData={this.fetchDashboardData}
						toggleLoginFlag={this.toggleLoginFlag}
					/>
				)} */}
				<section className={`dashboard-section`}>
					<div
						className={`${(this.state.action === 'openShortcut' && 'close-sidenav-active') || ''} close-sidenav`}
						onClick={() => this.handleModalState('', {} as IMyLinks)}
					>
						<CloseSideNav />
					</div>
					<MyShortcutSideNav
						action={this.state.action}
						partnersLink={partnersLink}
						myLink={myLink}
						deleteData={this.deleteData}
						handleModalState={this.handleModalState}
					/>
					<div className='dashboard-header d-flex justify-content-space-between align-items-center'>
						<h1 className='font--24px font-weight--500 line-height-30px text--dark-blue no-margin'>
							<Translate text={localizationConstants.dashboard} />
						</h1>
						<div className='d-flex justify-content-space-between align-items-center'>
							<Button
								className='white-bg btn dashboard-btn line-height--20px font--14px dashboard-btn dashboard-active cursor-unset'
								btnType='primary'
							>
								<Translate text={localizationConstants.myDashboard} />
							</Button>
						</div>
						<div className='customize-buttons__wrapper mr-30'>
							{isCustomMode && (
								<>
									<Button
										type='button'
										className='mr-8 button-size--medium'
										onClick={() => this.setState({ isCustomMode: !isCustomMode })}
									>
										<Translate text={localizationConstants.cancel} />
									</Button>
									<Button
										type='button'
										btnType='primary'
										className='button-size--medium'
										// onClick={this.handleSaveCustomizeDashboard}
										loading={loading}
									>
										<Translate text={localizationConstants.save} />
									</Button>
								</>
							)}
							{!isCustomMode && (
								<Button
									btnType='secondary'
									type='button'
									className='button-size--medium display--none'
									onClick={() => this.setState({ isCustomMode: !isCustomMode })}
								>
									<Translate text={localizationConstants.customize} />
								</Button>
							)}
						</div>
					</div>
					{loading && <Loader />}
					{!loading &&
						dataMapper.map((data) => (
							<div key={data.section} className={`cards-container ${(isCustomMode && 'customize-mode__container ') || ''}`}>
								{data.showLabel && <label className='font--16px line-height--22px font-weight--400 mb-23'>{data.label}</label>}
								<div className='sortable__container'>
									<FavouriteSortableContainer
										data={data}
										deleteData={this.deleteData}
										onSaveNews={this.saveNews}
										toggleCardView={this.handleToggleCardView}
										onEnd={this.onEnd}
										costModalUpdateView={this.costModalUpdateView}
										onSaveTender={this.saveTender}
										news_widget_animation={news_widget_animation}
									/>
								</div>
							</div>
						))}
				</section>
				{(this.state.action === 'addShortcut' || this.state.action === 'editShortcut') && (
					<AddShortcutPopup
						action={this.state.action}
						handleClose={() => this.handleModalState('')}
						fetchDashboardData={this.fetchDashboardData}
						elementId={this.state.selectedShortcut.element_id}
						initialValues={this.state.selectedShortcut.component_data}
					/>
				)}
			</div>
		);
	}

	findIndexById = (array: IDashboardData[], id: number) => {
		return array.findIndex((item) => item.type === 'cost_model' && (item as IDashboardType<ICostModal, any>).cost_model_id === id.toString());
	};

	fetchAnnouncements = () => {
		httpService
			.get(API_CONFIG.path.getAnnouncements)
			.then((response) => {
				if (!response.reqCanceled) {
					this.setState({
						announcementData: response
					});
				}
			})
			.catch((err) => {
				console.error(err);
			});
	};

	updateViewType = (dataArray: IDashboardData[], index: number, viewType: string) => {
		if (index !== -1) {
			dataArray[index] = {
				...dataArray[index],
				component_data: {
					...(dataArray[index] as IDashboardType<ICostModal, any>).component_data,
					view_type: viewType
				}
			};
		}
	};

	costModalUpdateView = (id: number) => {
		const favorite = [...this.state.favoritesData];
		const materialData = [...this.state.myData];
		const newsData = [...this.state.myNewsData];
		const favoriteIndex = this.findIndexById(this.state.favoritesData, id);
		const materialIndex = this.findIndexById(this.state.myData, id);
		const newsIndex = this.findIndexById(this.state.myNewsData, id);

		let viewType = '';
		if (favoriteIndex !== -1) {
			const componentData = (this.state.favoritesData[favoriteIndex] as IDashboardType<ICostModal, any>).component_data;
			viewType = componentData.view_type === 'chart' ? 'doughnut' : componentData.view_type === 'doughnut' ? 'table' : 'chart';
		} else if (materialIndex !== -1) {
			const componentData = (this.state.myData[materialIndex] as IDashboardType<ICostModal, any>).component_data;
			viewType = componentData.view_type === 'chart' ? 'doughnut' : componentData.view_type === 'doughnut' ? 'table' : 'chart';
		} else if (newsIndex !== -1) {
			const componentData = (this.state.myNewsData[newsIndex] as IDashboardType<ICostModal, any>).component_data;
			viewType = componentData.view_type === 'chart' ? 'doughnut' : componentData.view_type === 'doughnut' ? 'table' : 'chart';
		}

		httpService
			.put(`${API_CONFIG.path.costModalUpdateView}/${id}`, { view_type: viewType })
			.then(() => {
				this.updateViewType(favorite, favoriteIndex, viewType);
				this.updateViewType(materialData, materialIndex, viewType);
				this.updateViewType(newsData, newsIndex, viewType);
				this.setState({
					favoritesData: favorite,
					myData: materialData,
					myNewsData: newsData
				});
			})
			.catch((error) => {
				console.error(error, 'error');
			});
	};

	onEnd = (event: Sortable.SortableEvent) => {
		const { favoritesData, myData, myNewsData } = this.state;
		let copyOfFavoritesData = [...favoritesData];
		let copyOfMyData = [...myData];
		let copyOfMyNewsData = [...myNewsData];
		const fromClassName = event.from.className;
		const toClassName = event.to.className;
		const oldIndex = Number(event.oldIndex);
		const newIndex = Number(event.newIndex);
		if (fromClassName !== toClassName) {
			if (fromClassName.includes(DashboardSection.FAVORITE_SECTION) && toClassName.includes(DashboardSection.MATERIAL_SECTION)) {
				const { copyOfPullData, copyOfPutData } = onSwapBetweenList(oldIndex, newIndex, [...favoritesData], [...myData]);
				copyOfFavoritesData = copyOfPullData;
				copyOfMyData = copyOfPutData;
			} else if (fromClassName.includes(DashboardSection.FAVORITE_SECTION) && toClassName.includes(DashboardSection.NEWS_SECTION)) {
				const { copyOfPullData, copyOfPutData } = onSwapBetweenList(oldIndex, newIndex, [...favoritesData], [...myNewsData]);
				copyOfFavoritesData = copyOfPullData;
				copyOfMyNewsData = copyOfPutData;
			} else if (fromClassName.includes(DashboardSection.MATERIAL_SECTION) && toClassName.includes(DashboardSection.FAVORITE_SECTION)) {
				const { copyOfPullData, copyOfPutData } = onSwapBetweenList(oldIndex, newIndex, [...myData], [...favoritesData]);
				copyOfMyData = copyOfPullData;
				copyOfFavoritesData = copyOfPutData;
			} else if (fromClassName.includes(DashboardSection.MATERIAL_SECTION) && toClassName.includes(DashboardSection.NEWS_SECTION)) {
				const { copyOfPullData, copyOfPutData } = onSwapBetweenList(oldIndex, newIndex, [...myData], [...myNewsData]);
				copyOfMyData = copyOfPullData;
				copyOfMyNewsData = copyOfPutData;
			} else if (fromClassName.includes(DashboardSection.NEWS_SECTION) && toClassName.includes(DashboardSection.FAVORITE_SECTION)) {
				const { copyOfPullData, copyOfPutData } = onSwapBetweenList(oldIndex, newIndex, [...myNewsData], [...favoritesData]);
				copyOfMyNewsData = copyOfPullData;
				copyOfFavoritesData = copyOfPutData;
			} else if (fromClassName.includes(DashboardSection.NEWS_SECTION) && toClassName.includes(DashboardSection.MATERIAL_SECTION)) {
				const { copyOfPullData, copyOfPutData } = onSwapBetweenList(oldIndex, newIndex, [...myNewsData], [...myData]);
				copyOfMyNewsData = copyOfPullData;
				copyOfMyData = copyOfPutData;
			}
		} else {
			if (fromClassName.includes(DashboardSection.FAVORITE_SECTION)) {
				if (favoritesData[newIndex] !== undefined) {
					copyOfFavoritesData = swapData(favoritesData, oldIndex, newIndex);
				}
			} else if (fromClassName.includes(DashboardSection.MATERIAL_SECTION)) {
				if (myData[newIndex] !== undefined) {
					copyOfMyData = swapData(myData, oldIndex, newIndex);
				}
			} else if (fromClassName.includes(DashboardSection.NEWS_SECTION)) {
				if (myNewsData[newIndex] !== undefined) {
					copyOfMyNewsData = swapData(myNewsData, oldIndex, newIndex);
				}
			}
		}
		this.setState(
			{
				favoritesData: copyOfFavoritesData,
				myData: copyOfMyData,
				myNewsData: copyOfMyNewsData
			},
			() => this.handleSaveCustomizeDashboard()
		);
	};

	handleModalState = (action: string, shortCut: IMyLinks = {} as IMyLinks) => {
		this.setState({
			action: action,
			// widgetSection: widgetSection,
			selectedShortcut: shortCut
		});
	};

	handleToggleCardView = (elementId: string, view: string) => {
		this.setState({ actionLoading: true });
		const payload = {
			view: view === CardView.LIST_VIEW ? CardView.GRID_VIEW : CardView.LIST_VIEW
		};
		httpService
			.post(`${API_CONFIG.path.changeViewForNews}/${elementId}`, payload)
			.then(() => {
				const favorite = [...this.state.favoritesData];
				const newsData = [...this.state.myNewsData];
				const materialData = [...this.state.myData];
				const indexOfCardInFavourites = favorite.findIndex((value) => value.element_id === elementId);
				if (indexOfCardInFavourites !== -1) {
					favorite[indexOfCardInFavourites] = {
						...favorite[indexOfCardInFavourites],
						view: payload.view
					};
				}
				const indexOfCardInNews = newsData.findIndex((value) => value.element_id === elementId);
				if (indexOfCardInNews !== -1) {
					newsData[indexOfCardInNews] = {
						...newsData[indexOfCardInNews],
						view: payload.view
					};
				}
				const indexOfCardInMaterial = materialData.findIndex((value) => value.element_id === elementId);
				if (indexOfCardInMaterial !== -1) {
					materialData[indexOfCardInMaterial] = {
						...materialData[indexOfCardInMaterial],
						view: payload.view
					};
				}
				this.setState({
					actionLoading: false,
					favoritesData: [...favorite],
					myNewsData: [...newsData],
					myData: [...materialData]
				});
			})
			.catch(() => this.setState({ actionLoading: false }));
	};

	toggleLoginFlag = () => {
		this.setState({ actionLoading: true });
		httpService
			.put(API_CONFIG.path.statusChange)
			.then(() => {
				let copyUserData = this.state.userData;
				copyUserData = {
					...copyUserData,
					is_login: true
				};
				authService.setAuthData(copyUserData);

				this.setState({
					actionLoading: false,
					loading: false,
					userData: copyUserData
				});
			})
			.catch(() => this.setState({ actionLoading: false }));
	};

	handleSaveCustomizeDashboard = () => {
		const { favoritesData, myData, myNewsData } = this.state;
		const getElementId = (data: { element_id: string }) => parseInt(data.element_id);
		const favoriteDataElementId = (favoritesData.length > 0 && favoritesData.map(getElementId)) || [];
		const materialDataElementId = (myData.length > 0 && myData.map(getElementId)) || [];
		const newsDataElementId = (myNewsData.length > 0 && myNewsData.map(getElementId)) || [];
		const payload = {
			1: favoriteDataElementId,
			2: materialDataElementId,
			3: newsDataElementId
		};
		this.setState({ actionLoading: true });
		httpService
			.post(`${API_CONFIG.path.dashboard}/change_position`, payload)
			.then(() => {
				notify(t(localizationConstants.widgetSwiped), 'success', { id: 'swiped' });
				this.setState({
					actionLoading: false,
					isCustomMode: false
				});
			})
			.catch(() => this.setState({ actionLoading: false, isCustomMode: false }));
	};

	fetchDashboardData = () => {
		this.setState({ loading: true });
		try {
			httpService
				.get(API_CONFIG.path.dashboard)
				.then((response) => {
					const favoriteData: IDashboardType<INewsComponent[] | IListComponent, INewsParams | IListComponentParams>[] =
						response.favorites.slice(0, 7);
					const newsData: IDashboardType<INewsComponent[], INewsParams>[] = response.meineNachrichten.slice(0, 7);
					const materialData: IDashboardType<IFutureTableComponentType[] | IListComponent, IFutureTableParams | IListComponentParams>[] =
						response.meineKurse.slice(0, 7);
					this.setState({
						loading: false,
						favoritesData: createChartConfig(favoriteData),
						myData: createChartConfig(materialData),
						myNewsData: createChartConfig(newsData),
						partnersLink: response.MBILinks,
						myLink: response.meineLinks
					});
				})
				.catch((err) => {
					this.setState({ loading: false });
					console.error('error', err);
				});
		} catch (error) {
			this.setState({ loading: false });
			console.error('error dashboard api', error);
		}
	};

	deleteData = (deleteId: string, action: boolean) => {
		this.setState({
			deleteId: deleteId,
			isOpen: action
		});
	};

	handleConfirmDelete = () => {
		this.setState({ actionLoading: true });
		httpService
			.deleteRequest(`${API_CONFIG.path.dashboard}/${this.state.deleteId}`)
			.then(() => {
				this.setState({ isOpen: !this.state.isOpen, actionLoading: false }, () => {
					if (this.state.action === 'openShortcut') {
						notify(`${t(localizationConstants.shortcutDeleted)}`, 'success', { id: 'deletedSuccessfully' });
					} else {
						notify(`${t(localizationConstants.removeFromDashboard)}`, 'success', { id: 'deletedSuccessfully' });
					}
				});
				this.fetchDashboardData();
			})
			.catch((err) => {
				this.setState({ actionLoading: false });
				console.error('error', err);
			});
	};

	saveNews = (newsId: number, action: boolean, cardIndex: number, section: string) => {
		const params = {
			id: newsId,
			action: action
		};

		httpService
			.post(API_CONFIG.path.saveNews, params)
			.then(() => {
				let tempDataArray = [] as IDashboardType<INewsComponent[], INewsParams | IListComponentParams>[];
				if (section === 'myNews') {
					tempDataArray = [...this.state.myNewsData] as IDashboardType<INewsComponent[], INewsParams>[];
				} else if (section === 'favorite') {
					tempDataArray = [...this.state.favoritesData] as IDashboardType<INewsComponent[], INewsParams | IListComponentParams>[];
				} else {
					tempDataArray = [...this.state.myData] as IDashboardType<INewsComponent[], INewsParams>[];
				}

				const componentDataArray = tempDataArray[cardIndex].component_data as INewsComponent[];
				componentDataArray.forEach((componentData: INewsComponent, index: number) => {
					if (componentData.id === newsId) {
						const tempNewsObj = { ...componentData, gemerkte: action ? 1 : 0 };
						tempDataArray[cardIndex].component_data.splice(index, 1, tempNewsObj);
					}
				});
				if (section === 'myNews') {
					this.setState({ myNewsData: tempDataArray });
				} else if (section === 'favorite') {
					this.setState({ favoritesData: tempDataArray });
				} else {
					this.setState({
						myData: tempDataArray
					});
				}
			})
			.catch((error) => {
				console.error('error', error);
			});
	};

	saveTender = (tenderId: number, action: boolean, cardIndex: number, section: string, rubrics: string) => {
		const payload = {
			rubrics,
			status: action,
			tenderId
		};

		httpService
			.put(API_CONFIG.path.bookmarkTender, payload)
			.then(() => {
				const tempDataArray =
					section === 'myNews'
						? ([...this.state.myNewsData] as IDashboardType<INewsComponent[], INewsParams>[])
						: section === 'favorite'
						? ([...this.state.favoritesData] as IDashboardType<INewsComponent[], INewsParams | IListComponentParams>[])
						: ([...this.state.myData] as IDashboardType<INewsComponent[], INewsParams>[]);

				const componentDataArray = tempDataArray[cardIndex].component_data as INewsComponent[];
				componentDataArray.forEach((componentData: INewsComponent, index: number) => {
					if (componentData.id === tenderId) {
						const tempNewsObj = { ...componentData, bookmarkStatus: action };
						tempDataArray[cardIndex].component_data.splice(index, 1, tempNewsObj);
					}
				});
				if (section === 'myNews') {
					this.setState({ myNewsData: tempDataArray });
				} else if (section === 'favorite') {
					this.setState({ favoritesData: tempDataArray });
				} else {
					this.setState({
						myData: tempDataArray
					});
				}
			})
			.catch((error) => {
				console.error(error);
			});
	};

	getAnimateNewsWidget = () => {
		httpService.get(API_CONFIG.path.getAnimateNesWidget).then((response) => {
			this.setState({
				news_widget_animation: response.news_widget_animation
			});
		});
	};
}

export default DashboardContainer;
