import React, { Fragment, PropsWithChildren, useMemo } from 'react';
import { useLocation } from 'react-router-dom';
import { useSelector } from 'react-redux';
import get from 'lodash/get';

import FreezeUserPopup from 'shared/components/freezeUserPopup/freezeUserPopup';
import Sidebar from 'shared/components/navigation/sidenav';
import TopHeader from 'shared/components/navigation/topHeader';
import ReturnTakeOverPopup from 'shared/components/returnTakeOverPopup/returnTakeOverPopup';
import { WINDOW_WIDTH } from 'shared/constants/constants';
import ColorLayout from 'shared/hoc/colorLayout/colorLayout';
import { State } from 'shared/interface';

import MatfoxxBot from 'features/admin/component/matfoxxBot';
import { useSessionTracking } from 'shared/hooks/useSessionTracking';

const Layout: React.FC<PropsWithChildren> = ({ children }) => {
	const location = useLocation();
	const { userData } = useSelector((state: State) => state.auth);

	const pathName = useMemo(() => location.pathname, [location.pathname]);
	const isIframe = useMemo(() => pathName.includes('iframe'), [pathName]);

	const adminId = useMemo(() => get(userData, 'admin_id', null), [userData]);

	//custom hook for session and page tracking
	useSessionTracking();

	return (
		<ColorLayout id='wrapper' className={adminId ? 'height--100vh-40px' : 'height-100vh'}>
			{!isIframe ? (
				<Fragment>
					<Sidebar className={adminId ? 'height--100vh-40px' : 'height-100vh'} />
					<div id='page-wrapper' className={WINDOW_WIDTH <= 640 ? 'mobile-page-wrapper' : 'gray-bg'}>
						<TopHeader />
						<div className='main-data-container'>{children}</div>
						<div className='position--absolute right-0 bottom-0 z-index--100'>
							<MatfoxxBot />
						</div>
					</div>
					{adminId && <ReturnTakeOverPopup adminId={adminId} />}
				</Fragment>
			) : (
				<div>{children}</div>
			)}
			{userData.is_freeze && <FreezeUserPopup />}
		</ColorLayout>
	);
};

export default Layout;
