import { useCallback, useEffect, useRef } from 'react';
import { useDispatch } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { useLocation } from 'react-router-dom'; // Import useLocation for getting the current path

import { createAction } from 'shared/util/utility';
import localizationConstants from 'shared/util/translation/constants';

import * as actionTypes from 'store/action-types';
import * as LoginActions from 'features/login/store/login.action';

export const useInactivityTimer = (timeout = 1800000) => {
	const { t } = useTranslation();
	const timerRef = useRef<ReturnType<typeof setTimeout>>();
	const dispatch = useDispatch();
	const location = useLocation(); // Get the current path

	const onLogout = useCallback(() => {
		LoginActions.logout(dispatch);
		dispatch(
			createAction(actionTypes.UPDATE_SITE_CONFIG, {
				sessionExpireMessage: t(localizationConstants.inActivityMessage)
			})
		);
		if (timerRef.current) {
			clearTimeout(timerRef.current);
		}
	}, [dispatch, t]);

	const resetTimer = useCallback(() => {
		// If user is on the login page, don't reset the timer
		if (location.pathname === '/login') return;

		if (timerRef.current) {
			clearTimeout(timerRef.current);
		}
		timerRef.current = setTimeout(onLogout, timeout);
	}, [onLogout, timeout, location.pathname]);

	useEffect(() => {
		resetTimer();
		return () => {
			if (timerRef.current) {
				clearTimeout(timerRef.current);
			}
		};
	}, [resetTimer]);

	return resetTimer;
};
