import { IColor, IForecastGroupData, IMaterialGroupData, INewsGroupData } from 'features/admin/interface/admin';
import { ISpanMapper } from 'features/dashboard/interface/dashboard';
import { IStrokeColors } from 'features/futuretable/interface/futureTable';
import { IKebabMenuList } from 'features/material/interface/material';
import isEmpty from 'lodash/isEmpty';
import queryString from 'query-string';
import { CSSProperties } from 'react';
import i18n from 'shared/util/localization';
import localizationConstants from 'shared/util/translation/constants';
import { IRadioButtonArray, ITableHeader, QueryParameters } from '../interface';

const firstLevelBreadcrumbs = [{ name: 'Home', link: '/' }];
const { t } = i18n;
const API_CONFIG = {
	baseUrl: `${process.env.REACT_APP_BASE_URL}`,
	path: {
		getTotalUserCounts: 'admin/analytics/get_active_user',
		getAnalyticsDashboardData: 'report/dashboard-graph-statistics',
		getUserStatisticsSessions: 'report/user-sessions-statistics',
		getUserSessions: 'report/user-sessions',
		getActiveUserCounts: 'report/active-users-counts',
		userPageVisitAnalytics: 'tracks/page-visits',
		userSessionAnalytics: 'tracks/session',
		getAnimateNesWidget: 'user/profile/dashboard_widgets_view',
		getShowPartnerLink: 'user/profile/show_partner_section',
		savePersonalizeData: 'user/profile/personlization',
		getPersonalizeData: 'user/profile/user_personlization',
		getPartnerLink: 'dashboard/partner_link',
		sharedSlackError: 'shared/slack_error',
		whitelabelDeleteNewsList: 'admin/whitelabel/news/delete-list',
		userWhitelabelAllNews: 'whitelabel/all_news',
		adminNewsRenameList: 'admin/news/rename_list',
		whitelabelNewsGroupVisibility: 'admin/news/group-visibility',
		whitelabelNewsListVisibility: 'admin/whitelabel/news/list-visibility',
		whitelabelNewsGroupVisiblity: 'admin/whitelabel/news/group-visibility',
		whitelabelNewsListDragAndDrop: 'admin/whitelabel/news/drag-drop-materials-list',
		whitelabelNewsGroupDragAndDrop: 'admin/whitelabel/news/drag-drop-group',
		deleteWhitelabelNewsGroup: 'admin/whitelabel/news/delete-group',
		updateWhitelabelNewsGroup: 'admin/whitelabel/news/update-group',
		createWhitelabelNewsGroup: 'admin/whitelabel/news/create-group',
		getWhitelabelNewsGroupDetail: 'admin/whitelabel/news/detail/group',
		whitelabelNewsStructure: 'admin/whitelabel/all_news',
		userWhitelabelMaterialList: 'whitelabel/lists',
		userWhitelabelAllMaterials: 'whitelabel/all_materials',
		materialListVisiblity: 'admin/material/list_visibility',
		whitelabelListVisibility: 'admin/whitelabel/list-visibility',
		materialGroupVisiblity: 'admin/material/group_visibility',
		whitelabelGroupVisiblity: 'admin/whitelabel/group-visibility',
		updateWhitelableMaterialVisiblity: 'admin/whitelabel/change_visibility_material',
		deleteWhitelabelGroup: 'admin/whitelabel/material/delete-group',
		deleteWhitelabelList: 'admin/whitelabel/material/delete-material-list',
		deleteWhitelabelListEntries: 'admin/whitelabel/material/delete-list-entries',
		addWhitelabelMaterialList: 'admin/whitelabel/add-material-list',
		updateWhitelabelMaterialList: 'admin/whitelabel/update-material-list',
		updateWhitelabelGroup: 'admin/whitelabel/update-group',
		fetchWhitelabelGroupDetails: 'admin/whitelabel/detail/group',
		whitelabelCreateNewGroup: 'admin/whitelabel/create-group',
		whitelabelDataDragandDropMaterialList: 'admin/whitelabel/drag-drop-materials-list',
		whitelabelDataDragandDropMaterialListEntries: 'admin/whitelabel/drag-drop-materials-list-entries',
		tenderFinancer: 'tenders/all/financers',
		exportCourseHistoryNew: 'download/chartDataV2',
		adminDownloadIntrdayData: 'course/intraday/download',
		getChartData4: 'course/courseHistoryV5',
		chartAvailability: 'materials/chart_avabilities',
		updateChartView: 'materials/update_chart_view',
		assignBackpack: 'admin/backpack/course',
		isNewsFavourite: 'news/isfavourite',
		backpackMassValidity: 'admin/backpack/courseandcourselist',
		materialMassValidity: 'admin/permissions/massvalidity',
		maintenance: 'shared/maintenance',
		deleteFromDashboard: 'costmodel/delete_from_dashboard',
		updateHistoryData: 'course/updateHistoryData',
		updateCostmodelOnDashboard: 'dashboard/addCostModelToDashboard',
		getMyMatflixxModals: 'costmodel/visible/model_list',
		costModalDetails: 'costmodel/all_charts',
		getProductDetails: 'costmodel/product_details',
		uploadCostModalImage: 'costmodel/upload/cost/modal',
		createCostModal: 'costmodel/create',
		getCostModalList: 'costmodel/model_list',
		getCostModalChart: 'costmodel/chart',
		costModalUpdateView: 'costmodel/update_view',
		getCostModalTableData: 'costmodel/table',
		getDoughnutChartData: 'costmodel/pie_chart',
		getModalDetails: 'costmodel/model_details',
		updateCostModal: 'costmodel',
		costModalDragAndDrop: 'costmodel/drag_drop/change',
		costModalTableRange: 'costmodel/table_by_date',
		login: 'auth/login',
		user: 'user',
		permission: 'permission',
		logout: 'auth/logout',
		forgetPassword: 'password/forgot',
		resetPassword: 'auth/password/reset',
		profile: 'user/profile',
		changePassword: 'password/change_password',
		globalSearch: 'global/search/get-material-search',
		materialSearch: 'global/search/material_search',
		dashboard: 'dashboard',
		deleteDashboardElement: 'dashboard',
		materialNav: 'materials',
		courseList: 'materials/lists',
		myMaterialCourseList: 'materials/lists',
		futuretable: 'course/furute-table-lists',
		addFutureTableToDashboard: 'dashboard/addFutureTableToDashboard',
		addCourseListToDashboard: 'dashboard/addCourseListToDashboard',
		deleteMyMaterialCourseList: 'course/me/courseitem',
		exportFutureTableXLS: 'course/exportFutureTableXLS',
		getChartData: 'course/courseHistory',
		getChartDataV2: 'course/courseHistoryV2',
		setMaterialAlert: 'course/alerts/set-alerts-materials',
		newsArticle: 'news',
		newsArticleV2: 'news/listv2',
		newsArticleV3: 'news/listv3',
		saveNewsList: 'news/me',
		saveNews: 'news/favourite',
		allNewsCategories: 'news/menu/new',
		newsCategoryDropdown: 'news/dropdown/list',
		optionsCategory: 'news/options/categories',
		addNewsToDashboard: 'dashboard/addNewsDashboard',
		allMaterialData: 'materials/all_materials',
		getMaterialSearchWord: 'materials/search/word',
		courseListDropDown: 'materials/drop-down/list',
		addWidgetData: 'dashboard/selection_data',
		addMaterialToDashboard: 'dashboard/add_multiple_items',
		addChartToDashboard: 'dashboard/addChartToDashboard',
		saveMaterial: 'materials/save/material',
		allMaterialCourseLists: 'materials/all_lists',
		myMaterialList: 'course/me',
		allMaterialAlertList: 'course/alerts/get-alerts-materials',
		allNewsAlertList: 'course/alerts/get-alerts-news',
		setNewsAlert: 'course/alerts/set-alerts-news',
		deleteMaterialAlert: 'course/alerts/delete-alerts-materials',
		deleteNewsAlert: 'course/alerts/delete-alerts-news',
		updateNewsAlert: 'course/alerts/update-alerts-news',
		updateMaterialAlert: 'course/alerts/update-alerts-materials',
		addShortcut: 'dashboard/add-link-dashboard',
		exportCourseHistory: 'course/downloadCourseHistoryNew',
		editShortcut: 'dashboard/update-link-dashboard',
		userList: 'admin/users',
		alertBaseCount: 'course/alerts/get-base-alerts',
		forecastList: 'forecast/list',
		forecastDetails: 'forecast/list/detail',
		adminMaterialList: 'admin/permissions/courselist',
		currencyList: 'materials/list/currency',
		countryList: 'materials/lists/countries',
		adminMaterialSearch: 'admin/material/search/word',
		authorizeMaterialGroup: 'admin/permissions/courselist',
		courseAuthorization: 'admin/permissions/course',
		adminNewsList: 'admin/permissions/newsList',
		newsAuthorization: 'admin/permissions/newsList',
		adminForecastList: 'admin/permissions/foreCast',
		assignMaterialToAdmin: 'admin/permissions/course',
		forecastAuthorization: 'admin/permissions/forecastList',
		updateUser: 'admin/users/update',
		uploads: 'admin/uploads/excel',
		register: 'register',
		updateNews: 'admin/news/update',
		deleteNews: 'admin/news/delete',
		mbiListDropdown: 'admin/material/drop-down-mbi-material-list',
		addMBIList: 'admin/material/add-mbi-material-list',
		adminHistoryDownload: 'admin/material/history',
		adminMaterialData: 'admin/material/all_materials',
		adminUpdateMaterial: 'admin/material/update-material-list',
		adminDeleteMaterial: 'admin/material/delete-material-list',
		adminDropdownMaterialList: 'admin/material/drop-down-material-list',
		adminListEntries: 'admin/material/list_entries',
		adminChangeMaterialList: 'admin/material/change-material-list',
		partner: 'admin/partner',
		getAdminUsers: 'admin/users',
		addPartnerLink: 'admin/partner',
		deletePartnerLink: 'admin/partner',
		updatePartnerLink: 'admin/partner/update',
		createPassword: 'password/reset',
		updateService: 'auth/update-service',
		statusChange: 'auth/status/islogin',
		getAdminForecastList: 'admin/forecast/box/list',
		getForecastCategories: 'admin/forecast/cateogries',
		uploadForecast: 'admin/forecast/upload',
		forcastDownload: 'forecast/download',
		changeViewForNews: 'dashboard/changeViewForNews',
		deleteListEntries: 'admin/material/delete-list-entries',
		automaticDashboard: 'dashboard/blankDashboard',
		markAsAllRead: 'course/alerts/update_alerts_read',
		updateLanguage: 'user/profile/update_language',
		getProtocolList: 'admin/tables/protokoll-list',
		adminCategory: 'admin/material/pricelist',
		adminApiList: 'admin/permissions/material',
		materialVisibility: 'admin/material/change_visibility_material',
		adminApiPermission: 'admin/permissions/courselist/individual',
		adminApiMasterDataDownload: 'admin/permissions/courselist/download',
		xApiFileDownload: 'x-api/quotes',
		adminTableDownload: 'admin/tables/download',
		downloadMyMaterialAdminKurs: 'admin/material/download/lists',
		downloadMasterData: 'admin/material/master',
		takeOverAccount: 'admin/users/takeover',
		returnTakeOver: 'admin/users/return-take-over',
		getMaterialDetails: 'course/alerts/get_details_materials',
		adminAllMaterialList: 'admin/material/all_materials_list',
		adminDataDragandDrop: 'admin/material/drag_drop_materials_list',
		adminDragAndDropList: 'admin/material/drag_drop_list',
		adminDragAndDropgroup: 'admin/material/drag_drop_group',
		addChartInformation: 'admin/material/add_info_chart',
		addBackpack: 'admin/backpack',
		renameMaterialList: 'admin/material/rename_material_list',
		deleteMaterialList: 'admin/material/delete_material_list',
		fetchBackpackMaterials: 'admin/backpack/materials',
		authorizeBackpackMaterialGroup: 'admin/backpack/courselist',
		backpackCourseAuthorization: 'admin/backpack/course',
		fetchBackpackNews: 'admin/backpack/newsList',
		fetchBackpackForecast: 'admin/backpack/foreCast',
		newsBackpackAuthorization: 'admin/backpack/newsList',
		forecastBackpackAuthorization: 'admin/backpack/forecastList',
		getBackpackList: 'admin/backpack/list',
		adminBackpackPermission: 'admin/backpack/backpack_user',
		backpackUserList: 'admin/backpack/users',
		exportAdminCourseListEntries: 'admin/material/download/lists',
		exportAdminMasterData: 'admin/material/master',
		getWhiteLabelGroups: 'admin/whitelabel/groups',
		whiteLabelUserList: 'admin/whitelabel/users_dropdown',
		addWhiteLabelGroup: 'admin/whitelabel/add_whitelabe_group',
		uploadWhiteLabelLogo: 'admin/whitelabel/upload',
		updateWhiteLabelGroup: 'admin/whitelabel/update_whitelabe_group',
		updateWhitelabelConfig: 'admin/whitelabel/update_whitelabe_config',
		addMaterialList: 'admin/permissions/addMaterialList',
		updateDateFormat: 'user/profile/update-date-formate',
		deleteWhiteLabelGroup: 'admin/whitelabel',
		fetchWhiteLabelGroup: 'costmodel/group_list',
		fetchCostModalList: 'costmodel/group_model',
		addModalToDashboard: 'costmodel/add_to_dashboard',
		updateMaterialAlertCount: 'course/alerts/update-alerts-materials-count',
		fetchGroupDropDownData: 'admin/material/dropdown/group_list',
		uploadGroupImage: 'shared/upload/group/image',
		createNewGroup: 'admin/material/create_group',
		deleteGroup: 'admin/material/delete_group',
		updateGroupName: 'admin/material/update_group',
		fetchMaterialGroupDetails: 'admin/material/detail/group',
		updateMaterialVisibility: 'admin/material/change_visibility_material',
		addMatfoxxOffers: 'matfoxx/add_offers_matfoxx',
		fetchMatfoxxOffers: 'matfoxx/get_offers_matfoxx',
		deleteMatfoxxOffers: 'matfoxx/delete-matfoxx-offer',
		addMatfoxxJokes: 'matfoxx/add_joke_matfoxx',
		fetchMatfoxxJokes: 'matfoxx/get_joke_matfoxx',
		deleteMatfoxxJokes: 'matfoxx/delete-matfoxx-joke',
		updateMatfoxxUserConfig: 'matfoxx/update_notification_matfoxx',
		getUserLoginTime: 'matfoxx/get_login_time',
		fetchMatfoxxData: 'matfoxx/notification_offer_joke_matfoxx',
		updateMatfoxxView: 'matfoxx/update_notification_view_matfoxx',
		fetchForecastGroupData: 'admin/forecast/group_list',
		uploadForecastGroupImage: 'admin/forecast/upload/forecast',
		createForecastGroup: 'admin/forecast/create_group',
		uploadAdminCostModalImage: 'admin/costmodel/upload/cost/modal',
		createCostModelGroup: 'admin/costmodel/create_group',
		getCostModalData: 'admin/costmodel/group/model',
		costModalVisibility: 'admin/costmodel/update_visibility',
		deleteCostModelGroup: 'admin/costmodel/delete_group',
		deleteCostModal: 'admin/costmodel/delete_costmodel',
		renameCostModal: 'admin/costmodel/update_costmodel_name',
		renameCostModalGroup: 'admin/costmodel/update_group',
		adminCostModalDragAndDrop: 'admin/costmodel/drag_drop_costmodel',
		deleteForecastGroup: 'admin/forecast/delete_group',
		changeForecastGroupVisibility: 'admin/forecast/update_visibility',
		getForecastGroupDetail: 'admin/forecast/detail/group',
		updateForecastGroup: 'admin/forecast/update_group_subgroup',
		fetchAdminNewsData: 'admin/news',
		adminAllNewsCategoriesList: 'admin/news/all_news_categories',
		newsGroupDragAndDrop: 'admin/news/drag_drop_sub_group',
		newsListDragAndDrop: 'admin/news/drag_drop_list',
		adminNewsGroupDropDownList: 'admin/news/all_groups',
		createNewsGroup: 'admin/news/create_group',
		deleteNewsGroup: 'admin/news/delete_group',
		deleteNewsList: 'admin/news/delete_categories',
		newsListVisibility: 'admin/news/list_visibility',
		getAdminNewsDetail: 'admin/news/detail/news/group',
		updateNewsGroup: 'admin/news/update_group',
		deleteNewsCategory: 'admin/news/delete_list',
		addAnnouncements: 'announcement/add_announcement',
		fetchAnnouncementsList: 'announcement/get_announcement',
		deleteAnnouncementsList: 'announcement/delete_announcement',
		getAnnouncements: 'announcement/get_all',
		deleteForecastFile: 'admin/forecast/delete/pdf',
		downloadBackpackMasterData: 'admin/backpack/master',
		adminBackpackApiList: 'admin/backpack/individual/material',
		chartInformation: 'materials/chart_information',
		getUnitData: 'course/unitConvter',
		getHistoryDropDown: 'materials/data_available_year',
		adminForecastDownload: 'admin/material/forecast/history',
		uploadSVGIcon: 'shared/upload/group/svg',
		allContinents: 'tenders/all/continent',
		allTenderSectors: 'tenders/all/sectors',
		allTendersList: 'tenders/list',
		bookmarkTender: 'tenders/bookmark',
		tenderFullArticle: 'tenders',
		addTendersToDashboard: 'dashboard/addTendersToDashboard',
		tenderAlerts: 'tenders/alert',
		getAdminTenderDetail: 'admin/permissions/tender',
		getBackpackTenderDetail: 'admin/backpack/tender',
		addAdvanceChartConfig: 'download/masterChartData',
		getChartMasterData: 'download/masterChartData',
		getBackpackGroupList: 'admin/group/all-list',
		createGroupConfig: 'admin/group/create-group',
		getGroupList: 'admin/group/get-all-group',
		deleteGroupList: 'admin/group/delete-group',
		updateGroupList: 'admin/group/update-group',
		getGroupUserDetails: 'admin/group/user-details',
		assignGroupToUser: 'admin/group/manage-user-to-group',
		getUserList: 'admin/group/list-all-user',
		getDashboardIFrameData: 'iframe',
		getIFrameChartData: 'iframe/chart',
		downloadGroupUserList: 'admin/group/user-list-download',
		getWhitelabelUserList: 'admin/whitelabel/get-user-by-whitelabel',
		assignUserToWhiteLabel: 'admin/whitelabel/add-user-to-whitelabel',
		removeUserFromWhitelabel: 'admin/whitelabel/user-from-whitelabel',
		createForecastBox: 'admin/forecast/box',
		fetchForecastBoxData: 'admin/forecast/boxes',
		fetchReportsList: 'forecast/boxes',
		fetchReportsBoxDetails: 'forecast/box',
		adminReportsDragAndDrop: 'admin/forecast/tree',
		whiteLabelReportsSubSectorsList: 'admin/whitelabel/forecast/sub-group-list',
		fetchWhiteLabelReportsTreeStructure: 'admin/whitelabel/forecast/box/list',
		createWhitelabelForecastBox: 'admin/whitelabel/forecast/box',
		deleteWhitelabelForecast: 'admin/whitelabel/forecast/delete_group',
		fetchWhiteLabelForecastGroupData: 'admin/whitelabel/forecast/group_list',
		fetchWhiteLabelForecastBoxData: 'admin/whitelabel/forecast/boxes',
		updateWhiteLabelForecastGroup: 'admin/whitelabel/forecast/update_group_subgroup',
		createWhiteLabelForecastGroup: 'admin/whitelabel/forecast/create-group',
		whiteLabelForecastDragAndDrop: 'admin/whitelabel/forecast/drag-drop/sub-group',
		whiteLabelForecastFileVisibility: 'admin/whitelabel/file',
		fetchWhiteLabelForecastBoxes: 'whitelabel/forecast/boxes',
		fetchWhiteLabelForecastBoxDetails: 'whitelabel/forecast/box/detail',
		fetchWhitelabelForecastDetails: 'whitelabel/list/detail',
		updateForecastPdfVisibility: 'admin/forecast/file',
		whitelabelAddWidgetData: 'whitelabel/selection_data',
		downloadUserList: 'admin/users/export_user',
		downloadCountries: 'admin/tables/countries',
		downloadCurrencies: 'admin/tables/currency',
		downloadUnits: 'admin/tables/units',
		downloadTenderCountries: 'admin/tables/announcement_countries',
		downloadNotations: 'admin/tables/queatation',
		fetchMasterDataTableData: 'admin/tables/dropdown/master',
		fetchSourceDataTableData: 'admin/tables/source',
		downloadAdminMasterData: 'admin/tables/master'
	}
};

const getUrl = (url: string, params: QueryParameters | any = {}): string => {
	if (!url.includes('https')) {
		let urlString = `${API_CONFIG.baseUrl}/${url}`;
		if (params && !isEmpty(params)) {
			Object.keys(params).forEach((key) => {
				if (params[key] == null || params[key] === '') {
					delete params[key];
				}
			});
			urlString += `?${queryString.stringify(params)}`;
		}
		return urlString;
	}
	return url;
};
const getDifferenceDaysForOneYear = (endDate: any) => {
	const currentYear = endDate.getFullYear();
	const startDate = new Date(currentYear, 0, 1);
	const Difference_In_Time = endDate.getTime() - startDate.getTime();
	return Math.round(Difference_In_Time / (1000 * 3600 * 24));
};
const checkMaterialData = (data: INewsGroupData[] | IForecastGroupData[] | IMaterialGroupData[], operationFor: string) => {
	let isChecked = false;
	data.map((d: INewsGroupData | IForecastGroupData | IMaterialGroupData) => {
		if (
			(operationFor === 'forecast' && (d as IForecastGroupData).bp_id === null) ||
			(operationFor === 'news' && (d as INewsGroupData).bn_id === null)
		) {
			isChecked = true;
		} else {
			isChecked = false;
		}
	});
	return isChecked;
};
const checkBackpackGroupData = (data: INewsGroupData[] | IForecastGroupData[] | IMaterialGroupData[], operationFor: string) => {
	let isChecked = false;
	data.map((d: INewsGroupData | IForecastGroupData | IMaterialGroupData) => {
		if (
			(operationFor === 'forecast' && (d as IForecastGroupData).is_backpack) ||
			(operationFor === 'news' && (d as INewsGroupData).is_backpack)
		) {
			isChecked = true;
		} else {
			isChecked = false;
		}
	});
	return isChecked;
};
const pageOptions: number[] = [20, 50, 100];
const PER_PAGE = 20;
const ENVIRONMENT = process.env.REACT_APP_ENVIRONMENT || '';
const getFileUploadFolder = (key: string) => `image/Blastv4/${ENVIRONMENT}/${key}`;
const WINDOW_WIDTH = window.innerWidth;
const URL_REGEX = new RegExp('(https?://)?([\\da-z.-]+)\\.([a-z.]{2,6})[/\\w .-]*/?');
/**
 * reactSelectStyles - css style object for react-select
 */
const reactSelectStyles = {
	option: (base: CSSProperties, state: any) => ({
		...base,
		// borderBottom: '1px solid #e7e7e7',
		color: state.isSelected ? 'white' : state.isFocused ? '' : '#272A30',
		padding: 8,
		backgroundColor: state.isSelected ? '#081d34' : state.isFocused ? '' : '',
		':active': {
			backgroundColor: '#081d34',
			color: 'white'
		},
		':hover': {
			backgroundColor: '#081d34',
			color: 'white'
		},
		':focus': {
			backgroundColor: '#081d34',
			outline: 0,
			color: 'white'
		},
		cursor: 'pointer'
	}),
	menu: (base: CSSProperties) => ({
		...base,
		zIndex: 3,
		marginTop: 4,
		border: '1px solid #D4D6D9',
		borderRadius: 8
	}),
	menuList: (base: CSSProperties) => ({
		...base,
		padding: 0,
		backgroundColor: '#ffffff !important',
		overflowX: 'hidden'
	}),
	clearIndicator: (base: CSSProperties) => ({
		...base,
		cursor: 'pointer'
	}),
	dropdownIndicator: (base: CSSProperties, state: any) => ({
		...base,
		cursor: 'pointer',
		transition: 'all 0.2s ease',
		transform: state.selectProps.menuIsOpen ? 'rotate(180deg)' : 'none',
		color: '#081D34'
	}),
	indicatorSeparator: () => ({
		width: '0'
	}),
	control: () => ({
		// none of react-selects styles are passed to <View />
		display: 'flex',
		width: '100%',
		minHeight: '34px !important',
		border: '1px solid #EDEEEF',
		padding: '0px !important',
		borderRadius: '8px'
	}),
	singleValue: (base: CSSProperties, state: any) => {
		const opacity = state.isDisabled ? 0.5 : 1;
		const transition = 'opacity 300ms';
		return { ...base, opacity: opacity, transition: transition };
	}
};
/**
 * reactSelectStyles - css style object for react-select
 */
const multiReactSelectStyles = {
	option: (base: CSSProperties, state: any) => ({
		...base,
		// borderBottom: '1px solid #e7e7e7',
		color: state.isSelected && '#14304F',
		padding: 8,
		display: 'flex',
		flexDirection: 'row',
		justifyContent: 'flex-start',
		alignItems: 'center',
		backgroundColor: 'white',
		cursor: 'pointer',
		accentColor: ' #002c50 !important',
		':active': {
			backgroundColor: 'transparent',
			color: '#14304F'
		}
	}),
	menu: (base: CSSProperties) => ({
		...base,
		zIndex: 3,
		display: 'flex',
		marginTop: 4,
		border: '1px solid #D4D6D9',
		borderRadius: 4
	}),
	menuList: (base: CSSProperties) => ({
		...base,
		padding: 0,
		backgroundColor: '#ffffff !important',
		borderRadius: 4,
		width: '100%'
	}),
	clearIndicator: (base: CSSProperties) => ({
		...base,
		cursor: 'pointer'
	}),
	dropdownIndicator: (base: CSSProperties, state: any) => ({
		...base,
		cursor: 'pointer',
		transition: 'all 0.2s ease',
		transform: state.selectProps.menuIsOpen ? 'rotate(180deg)' : 'none',
		color: '#081D34',
		padding: 0
	}),
	indicatorSeparator: () => ({
		width: '0'
	}),
	control: () => ({
		// none of react-selects styles are passed to <View />
		display: 'flex',
		width: '250px',
		minHeight: '34px !important',
		border: '1px solid #EDEEEF',
		padding: '0px !important',
		borderRadius: '8px',
		backgroundColor: '#ffffff !important'
	}),
	singleValue: (base: CSSProperties, state: any) => {
		const opacity = state.isDisabled ? 0.5 : 1;
		const transition = 'opacity 300ms';
		return { ...base, opacity: opacity, transition: transition };
	}
};
const colorPallet = ['#4472C4', '#ED7D31', '#FFC000', '#70AD47', '#00B0F0', '#7030A0', '#FF0000', '#002060', '#000000'];
const kebabMenu: IKebabMenuList[] = [
	{ title: `${localizationConstants.addToDashboard}` },
	{ title: `${localizationConstants.rename}` },
	{ title: `${localizationConstants.extinguish}` }
];
const chartSettingColorPallet = ['#4472C4', '#ED7D31', '#FFC000', '#70AD47', '#00B0F0', '#7030A0', '#FF0000', '#002060', '#000000'];
const responsiveTableHEading = [`${t(localizationConstants.dueDate)}`, `${localizationConstants.courses}`, `${localizationConstants.change}`];
const ImageLink = `${process.env.REACT_APP_IMAGE_LINK}`;
const passwordRegex = /^(?=.*[A-ZÄÖÜß])(?=.*[a-zäöüß])(?=.*\d)(?=.*[\W_])[A-Za-zäöüÄÖÜß\d\W_]{8,24}$/;
const DATE_FORMAT_MAPPER: { [key: string]: string } = {
	'DD/MM/YYYY': 'dd-MM-yyyy',
	'MM/DD/YYYY': 'MM-dd-yyyy',
	'YYYY/MM/DD': 'yyyy-MM-dd'
};
const courseTableHeading: ITableHeader[] = [
	{ header: `${t(localizationConstants.name)}`, class: 'width--19' },
	{ header: `${t(localizationConstants.maturity)}`, class: 'width--10 text-align-right' },
	{ header: `${t(localizationConstants.date)}`, class: 'width--10 text-align-right' },
	{ header: `${t(localizationConstants.actual)}`, class: 'width--10 text-align-right' },
	{ header: `${t(localizationConstants.hist)}`, class: 'width--10 text-align-right' },
	{ header: `${t(localizationConstants.change)}`, class: 'width--10 text-align-right' },
	{ header: `${t(localizationConstants.high)}`, class: 'width--10 text-align-right' },
	{ header: `${t(localizationConstants.low)}`, class: 'width--10 text-align-right' },
	{ header: `${t(localizationConstants.currency)}`, class: 'width--10 text-align-right' }
];
const STROKESCOLORS: IStrokeColors = {
	ema: '#1f77b4',
	sma: '#ff7f0e',
	tma: '#d62728',
	wma: '#9467bd'
};
const csvHeaders = [
	{ label: `${t(localizationConstants.name)}`, key: 'name' },
	{ label: `${t(localizationConstants.maturity)}`, key: 'faelligkeit_f' },
	{ label: `${t(localizationConstants.date)}`, key: 'datum' },
	{ label: `${t(localizationConstants.actual)}`, key: 'hauptkurs' },
	{ label: `${t(localizationConstants.hist)}`, key: 'hist_kurs' },
	{ label: `${t(localizationConstants.change)}`, key: 'hist_perf' },
	{ label: `${t(localizationConstants.high)}`, key: 'hist_hoch' },
	{ label: `${t(localizationConstants.low)}`, key: 'hist_tief' },
	{ label: `${t(localizationConstants.currency)}`, key: 'waehrung' },
	{ label: `${t(localizationConstants.unit)}`, key: 'einheit' },
	{ label: `${t(localizationConstants.histDate)}`, key: 'datum' }
];

const newsBackpackMasterDataHeaders = [
	{ header: `${t(localizationConstants.sector)}`, key: 'group', width: 45 },
	{ header: `${t(localizationConstants.category)}`, key: 'category', width: 45 }
];

const forecastBackpackMasterDataHeaders = [
	{ header: `${t(localizationConstants.sector)}`, key: 'group', width: 45 },
	{ header: `${t(localizationConstants.name)}`, key: 'title', width: 45 }
];

const userListBackpackMasterDataHeaders = [
	{ header: `${t(localizationConstants.firstname)}`, key: 'name', width: 30 },
	{ header: `${t(localizationConstants.lastName)}`, key: 'lastName', width: 30 },
	{ header: `${t(localizationConstants.email)}`, key: 'email', width: 45 }
];

const customCsvHeaders = [
	{ header: `${t(localizationConstants.name)}`, key: 'name', width: 45 },
	{ header: `${t(localizationConstants.maturity)}`, key: 'faelligkeit_f', width: 15 },
	{ header: `${t(localizationConstants.date)}`, key: 'datum', width: 15 },
	{ header: `${t(localizationConstants.actual)}`, key: 'hauptkurs', width: 15 },
	{ header: `${t(localizationConstants.hist)}`, key: 'hist_kurs', width: 15 },
	{ header: `${t(localizationConstants.change)}`, key: 'hist_perf', width: 15, horizontal: 'right' },
	{ header: `${t(localizationConstants.high)}`, key: 'hist_hoch', width: 15 },
	{ header: `${t(localizationConstants.low)}`, key: 'hist_tief', width: 15 },
	{ header: `${t(localizationConstants.currency)}`, key: 'waehrung', width: 15 },
	{ header: `${t(localizationConstants.unit)}`, key: 'einheit', width: 15 },
	{ header: `${t(localizationConstants.histDate)}`, key: 'histDatum', width: 15 }
];

const groupUserListHeader = [
	{ header: `${t(localizationConstants.groupName)}`, key: 'group', width: 45 },
	{ header: `${t(localizationConstants.userId)}`, key: 'user_id', width: 45 },
	{ header: `${t(localizationConstants.email)}`, key: 'email', width: 45 },
	{ header: `${t(localizationConstants.promotionCode)}`, key: 'code', width: 45 }
];
const adminAdvancedSearchCsvHeaders = [
	{ header: 'symbol', key: 'symbol', width: 45 },
	{ header: 'boerse', key: 'boerse', width: 15 },
	{ header: 'basis_symbol', key: 'basis_symbol', width: 15 },
	{ header: 'isin', key: 'isin', width: 15 },
	{ header: 'faelligkeit', key: 'faelligkeit', width: 15 },
	{ header: 'faelligkeit_f', key: 'faelligkeit_f', width: 15, horizontal: 'right' },
	{ header: 'frei', key: 'frei', width: 15 },
	{ header: 'sichtbar', key: 'sichtbar', width: 15 },
	{ header: 'editierbar', key: 'editierbar', width: 15 },
	{ header: 'land', key: 'land', width: 15 },
	{ header: 'waehrung', key: 'waehrung', width: 15 },
	{ header: 'waehrung_ignore', key: 'waehrung_ignore', width: 15 },
	{ header: 'einheit', key: 'einheit', width: 15 },
	{ header: 'notierung', key: 'notierung', width: 15 },
	{ header: 'notierung_zusatz', key: 'notierung_zusatz', width: 15 },
	{ header: 'nkstellen', key: 'nkstellen', width: 15 },
	{ header: 'max_kursalter', key: 'max_kursalter', width: 15 },
	{ header: 'name_deu', key: 'name_deu', width: 45 },
	{ header: 'name_eng', key: 'name_eng', width: 45 },
	{ header: 'name_zusatz', key: 'name_zusatz', width: 45 },
	{ header: 'boersenname', key: 'boersenname', width: 35 },
	{ header: 'datenlieferant', key: 'datenlieferant', width: 15 },
	{ header: 'quelle', key: 'quelle', width: 15 },
	{ header: 'kunde', key: 'kunde', width: 15 },
	{ header: 'hinzugefuegt_am', key: 'hinzugefuegt_am', width: 35 },
	{ header: 'entfernt_am', key: 'entfernt_am', width: 35 },
	{ header: 'lagerbestand_symbol', key: 'lagerbestand_symbol', width: 35 },
	{ header: 'lagerbestand_boerse', key: 'lagerbestand_boerse', width: 35 },
	{ header: 'info', key: 'info', width: 35 },
	{ header: 'verantwortlich', key: 'verantwortlich', width: 35 },
	{ header: 'prozent_abweichung_erlaubt', key: 'prozent_abweichung_erlaubt', width: 35 }
];
const apiMasterDataCsvHeaders = [
	{ header: 'symbol', key: 'symbol', width: 15 },
	{ header: 'boerse', key: 'boerse', width: 15 },
	{ header: 'basis_symbol', key: 'basis_symbol', width: 15 },
	{ header: 'isin', key: 'isin', width: 15 },
	{ header: 'faelligkeit', key: 'faelligkeit', width: 15 },
	{ header: 'faelligkeit_f', key: 'faelligkeit_f', width: 15 },
	{ header: 'sichtbar', key: 'sichtbar', width: 15 },
	{ header: 'land', key: 'land', width: 15 },
	{ header: 'waehrung', key: 'waehrung', width: 15 },
	{ header: 'einheit', key: 'einheit', width: 15 },
	{ header: 'notierung', key: 'notierung', width: 15 },
	{ header: 'notierung_zusatz', key: 'notierung_zusatz', width: 15 },
	{ header: 'name_deu', key: 'name_deu', width: 60 },
	{ header: 'name_eng', key: 'name_eng', width: 60 },
	{ header: 'name_zusatz', key: 'name_zusatz', width: 60 }
];

const adminListEntriesHeader = [
	{ header: 'symbol', key: 'symbol', width: 15 },
	{ header: 'boerse', key: 'boerse', width: 15 },
	{ header: 'basis_symbol', key: 'basis_symbol', width: 15 },
	{ header: 'isin', key: 'isin', width: 15 },
	{ header: 'faelligkeit', key: 'faelligkeit', width: 15 },
	{ header: 'faelligkeit_f', key: 'faelligkeit_f', width: 15 },
	{ header: 'frei', key: 'frei', width: 15 },
	{ header: 'sichtbar', key: 'sichtbar', width: 15 },
	{ header: 'land', key: 'land', width: 15 },
	{ header: 'waehrung', key: 'waehrung', width: 15 },
	{ header: 'einheit', key: 'einheit', width: 15 },
	{ header: 'name_deu', key: 'name_deu', width: 60 },
	{ header: 'name_eng', key: 'name_eng', width: 60 },
	{ header: 'kurszeit', key: 'kurszeit', width: 40 },
	{ header: 'letzter', key: 'letzter', width: 15 },
	{ header: 'settlement', key: 'settlement', width: 15 },
	{ header: 'geld', key: 'geld', width: 15 },
	{ header: 'brief', key: 'brief', width: 15 },
	{ header: 'hauptkursfeld_soll', key: 'hauptkursfeld_soll', width: 40 }
];
const adminMasterDataHeaders = [
	{ header: 'symbol', key: 'symbol', width: 15 },
	{ header: 'boerse', key: 'boerse', width: 15 },
	{ header: 'basis_symbol', key: 'basis_symbol', width: 15 },
	{ header: 'isin', key: 'isin', width: 15 },
	{ header: 'faelligkeit', key: 'faelligkeit', width: 15 },
	{ header: 'faelligkeit_f', key: 'faelligkeit_f', width: 15 },
	{ header: 'frei', key: 'frei', width: 15 },
	{ header: 'sichtbar', key: 'sichtbar', width: 15 },
	{ header: 'editierbar_', key: 'editierbar_', width: 15 },
	{ header: 'land', key: 'land', width: 15 },
	{ header: 'waehrung', key: 'waehrung', width: 15 },
	{ header: 'waehrung_ignore', key: 'waehrung_ignore', width: 15 },
	{ header: 'einheit', key: 'einheit', width: 15 },
	{ header: 'notierung', key: 'notierung', width: 15 },
	{ header: 'notierung_zusatz', key: 'notierung_zusatz', width: 15 },
	{ header: 'nkstellen', key: 'nkstellen', width: 15 },
	{ header: 'max_kursalter', key: 'max_kursalter', width: 15 },
	{ header: 'name_deu', key: 'name_deu', width: 60 },
	{ header: 'name_eng', key: 'name_eng', width: 60 },
	{ header: 'name_zusatz', key: 'name_zusatz', width: 15 },
	{ header: 'boersenname', key: 'boersenname', width: 15 },
	{ header: 'datenlieferant', key: 'datenlieferant', width: 15 },
	{ header: 'quelle', key: 'quelle', width: 15 },
	{ header: 'kunde', key: 'kunde', width: 15 },
	{ header: 'hinzugefuegt_am', key: 'hinzugefuegt_am', width: 30 },
	{ header: 'entfernt_am', key: 'entfernt_am', width: 30 },
	{ header: 'lagerbestand_symbol', key: 'lagerbestand_symbol', width: 30 },
	{ header: 'lagerbestand_boerse', key: 'lagerbestand_boerse', width: 30 },
	{ header: 'info', key: 'info', width: 30 },
	{ header: 'verantwortlich', key: 'verantwortlich', width: 30 },
	{ header: 'prozent_abweichung_erlaubt', key: 'prozent_abweichung_erlaubt', width: 30 }
];
const matfoxxOffersListHeaders = [
	{ header: 'offer', key: 'offer', width: 15 },
	{ header: 'link', key: 'link', width: 15 },
	{ header: 'start_date', key: 'start_date', width: 15, style: { numFmt: 'yyyy-mm-dd' } },
	{ header: 'end_date', key: 'end_date', width: 15, style: { numFmt: 'yyyy-mm-dd' } }
];

const newsListHeaders = [
	{ header: 'kategorie_id', key: 'kategorie_id', width: 15 },
	{ header: 'kategorie', key: 'kategorie', width: 60 },
	{ header: 'kategorie_eng', key: 'kategorie_eng', width: 60 },
	{ header: 'quelle', key: 'quelle', width: 15 },
	{ header: 'grusel', key: 'grusel', width: 15 }
];
const matfoxxJokesListHeaders = [
	{ header: 'joke', key: 'joke', width: 60 },
	{ header: 'date', key: 'date', width: 30, style: { numFmt: 'yyyy-mm-dd' } }
];
const DateRangeMapper: { [key: string | number]: any } = {
	'1w': [7, 'days'],
	'1m': [1, 'month'],
	'3m': [3, 'month'],
	'6m': [6, 'month'],
	'1y': [1, 'year'],
	'3y': [3, 'year'],
	'5y': [5, 'year'],
	'10y': [10, 'year'],
	'20y': [20, 'year']
};
export const spanMapper: ISpanMapper = {
	'1m': 30,
	'3m': 90,
	'6m': 180,
	'1y': 365,
	ytd: getDifferenceDaysForOneYear(new Date()),
	max: 'max'
};
const createOption = (label: string) => {
	return { label: label, value: label };
};
const materialUserCourseTableHeading: ITableHeader[] = [
	{ header: `${t(localizationConstants.name)}`, class: 'width--19' },
	{ header: `${t(localizationConstants.kurszeit)}`, class: 'width--10 text-align-right' },
	{ header: `${t(localizationConstants.maturity)}`, class: 'width--10 text-align-right' },
	{ header: `${t(localizationConstants.currency)}`, class: 'width--10 text-align-right' }
];

const DATE_PLACEHOLDER_MAPPER: any = (langugae: string) => {
	return {
		'DD/MM/YYYY': langugae === 'de' ? 'TT/MM/JJJJ' : 'DD/MM/YYYY',
		'MM/DD/YYYY': langugae === 'de' ? 'MM/TT/JJJJ' : 'MM/DD/YYYY',
		'YYYY/MM/DD': langugae === 'de' ? 'JJJJ/MM/TT' : 'YYYY/MM/DD'
	};
};
const PROTOCOL_LIST_HEADER = [
	{ header: `${t(localizationConstants.userId)}`, class: '' },
	{ header: `${t(localizationConstants.uploadedBy)}`, class: '' },
	{ header: `${t(localizationConstants.fileName)}`, class: '' },
	{ header: `${t(localizationConstants.inserted)}`, class: 'text-align-right' },
	{ header: `${t(localizationConstants.updated)}`, class: 'text-align-right' },
	{ header: `${t(localizationConstants.processes)}`, class: 'text-align-right' },
	{ header: `${t(localizationConstants.mistake)}`, class: 'text-align-right' }
];
const courseDateRange: any = (language: string) => {
	return [
		{
			id: ' 1',
			title: `${(language === 'en' && '1W') || '1W'}`,
			value: '1w'
		},
		{
			id: ' 2',
			title: `${(language === 'en' && '1M') || '1M'}`,
			value: '1m'
		},
		{
			id: ' 3',
			title: `${(language === 'en' && '3M') || '3M'}`,
			value: '3m'
		},
		{
			id: ' 4',
			title: `${(language === 'en' && '6M') || '6M'}`,
			value: '6m'
		},
		{
			id: ' 5',
			title: `${(language === 'en' && '1Y') || '1J'}`,
			value: '1y'
		},
		{
			id: ' 6',
			title: `${(language === 'en' && '3Y') || '3J'}`,
			value: '3y'
		},
		{
			id: ' 7',
			title: `${(language === 'en' && '5Y') || '5J'}`,
			value: '5y'
		},
		{
			id: ' 8',
			title: `${(language === 'en' && '10Y') || '10J'}`,
			value: '10y'
		}
	];
};

const statisticsDateRange: any = (language: string) => {
	return [
		{
			id: ' 1',
			title: `${(language === 'en' && '1W') || '1W'}`,
			value: '1w'
		},
		{
			id: ' 2',
			title: `${(language === 'en' && '1M') || '1M'}`,
			value: '1m'
		},
		{
			id: ' 3',
			title: `${(language === 'en' && '3M') || '3M'}`,
			value: '3m'
		},
		{
			id: ' 4',
			title: `${(language === 'en' && '6M') || '6M'}`,
			value: '6m'
		},
		{
			id: ' 5',
			title: `${(language === 'en' && '1Y') || '1J'}`,
			value: '1y'
		},
		{
			id: ' 6',
			title: `${(language === 'en' && 'All') || 'All'}`,
			value: 'all'
		}
	];
};

const adminAuthUserNavbar = [
	{
		name: `${localizationConstants.materials}`,
		id: 0
	},
	{
		name: `${localizationConstants.API}`,
		id: 1
	},
	{
		name: `${localizationConstants.news}`,
		id: 2
	},
	{
		name: `${localizationConstants.forecasts}`,
		id: 3
	},
	{
		name: `${localizationConstants.backpack}`,
		id: 6
	},
	{
		name: `${localizationConstants.tenders}`,
		id: 7
	},
	{
		name: `Dashboards`,
		id: 5
	},
	{
		name: `${localizationConstants.myMaterials}`,
		id: 10
	},
	{
		name: `${localizationConstants.statistics}`,
		id: 11
	}
	// {
	// 	name: `${localizationConstants.statistics}`,
	// 	id: 4
	// },
];
const adminAuthBackpackNavbar = [
	{
		name: `${localizationConstants.materials}`,
		id: 0
	},
	{
		name: `${localizationConstants.API}`,
		id: 1
	},
	{
		name: `${localizationConstants.news}`,
		id: 2
	},
	{
		name: `${localizationConstants.forecasts}`,
		id: 3
	},
	{
		name: `${localizationConstants.tenders}`,
		id: 4
	}
];
const COLOR_FIELDS: IColor[] = [
	{ key: 'primary_color', label: 'Primary color' },
	{ key: 'primary_light_color', label: 'Primary light color' },
	{ key: 'primary_lighter_color', label: 'Primary lighter color' },
	{ key: 'accent_yellow_color', label: 'Accent yellow color' },
	{ key: 'success_color', label: 'Success color' },
	{ key: 'error_color', label: 'Error color' },
	{ key: 'red_light_color', label: 'Red light color' },
	{ key: 'blue_color', label: 'Blue color' },
	{ key: 'greyscale_0', label: 'Greyscale 0' },
	{ key: 'greyscale_5', label: 'Greyscale 5' },
	{ key: 'greyscale_10', label: 'Greyscale 10' },
	{ key: 'greyscale_20', label: 'Greyscale 20' },
	{ key: 'greyscale_25', label: 'Greyscale 25' },
	{ key: 'greyscale_30', label: 'Greyscale 30' },
	{ key: 'greyscale_40', label: 'Greyscale 40' },
	{ key: 'greyscale_50', label: 'Greyscale 50' },
	{ key: 'greyscale_60', label: 'Greyscale 60' },
	{ key: 'greyscale_70', label: 'Greyscale 70' },
	{ key: 'greyscale_80', label: 'Greyscale 80' },
	{ key: 'greyscale_90', label: 'Greyscale 90' },
	{ key: 'greyscale_100', label: 'Greyscale 100' }
];

const costModalTableHeader: ITableHeader[] = [
	{ header: `${localizationConstants.displayName}`, class: 'text-align-left' },
	{ header: `${t(localizationConstants.date)}`, class: 'text-align-center' },
	{ header: `${t(localizationConstants.price)}`, class: 'text-align-center' },
	{ header: `${t(localizationConstants.change)}`, class: 'text-align-center' }
];

const englishLocale = {
	dateTime: '%x, %X',
	date: '%-m/%-d/%Y',
	time: '%-I:%M:%S %p',
	periods: ['AM', 'PM'],
	days: ['Sunday', 'Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday', 'Saturday'],
	shortDays: ['Sun', 'Mon', 'Tue', 'Wed', 'Thu', 'Fri', 'Sat'],
	months: ['January', 'February', 'March', 'April', 'May', 'June', 'July', 'August', 'September', 'October', 'November', 'December'],
	shortMonths: ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec']
};

const germanLocale = {
	dateTime: '%A, der %e. %B %Y, %X',
	date: '%d.%m.%Y',
	time: '%H:%M:%S',
	periods: ['AM', 'PM'],
	days: ['Sonntag', 'Montag', 'Dienstag', 'Mittwoch', 'Donnerstag', 'Freitag', 'Samstag'],
	shortDays: ['So', 'Mo', 'Di', 'Mi', 'Do', 'Fr', 'Sa'],
	months: ['Januar', 'Februar', 'März', 'April', 'Mai', 'Juni', 'Juli', 'August', 'September', 'Oktober', 'November', 'Dezember'],
	shortMonths: ['Jan', 'Feb', 'Mrz', 'Apr', 'Mai', 'Jun', 'Jul', 'Aug', 'Sep', 'Okt', 'Nov', 'Dez']
};

const whiteSpceRegex = / +/g;

const mouseEdgeAppearance = {
	textFill: '#542605',
	stroke: '#05233B',
	strokeOpacity: 1,
	fill: '#FFF'
};

const IMAGE_EXPORT_BUTTON_ARRAY = ['PNG', 'JPEG', 'SVG', 'PDF'];

const adminMatrialForecastExcelHeaders = [
	{ header: 'symbol', key: 'symbol', width: 15 },
	{ header: 'boerse', key: 'boerse', width: 15 },
	{ header: 'datum', key: 'datum', width: 15 },
	{ header: 'letzter', key: 'letzter', width: 15 },
	{ header: 'umsatz', key: 'umsatz', width: 15 },
	{ header: 'settlement', key: 'settlement', width: 15 },
	{ header: 'oi', key: 'oi', width: 15 },
	{ header: 'geld', key: 'geld', width: 15 },
	{ header: 'brief', key: 'brief', width: 15 },
	{ header: 'erster', key: 'erster', width: 15 },
	{ header: 'hoch', key: 'hoch', width: 15 },
	{ header: 'tief', key: 'tief', width: 15 }
];

const adminMatrialIntradayHeaders = [
	{ header: 'symbol', key: 'symbol', width: 15 },
	{ header: 'boerse', key: 'boerse', width: 15 },
	{ header: 'datum', key: 'datum', width: 15 },
	{ header: 'zeit', key: 'zeit', width: 15 },
	{ header: 'kurs', key: 'kurs', width: 15 },
	{ header: 'umsatz', key: 'umsatz', width: 15 },
	{ header: 'kurstyp', key: 'kurstyp', width: 15 }
];

const checkExpiredDate = (expiredDate: any) => {
	const today = new Date();
	const expirationDate = new Date(expiredDate);
	return expirationDate < today;
};

const CHARTMAPPER: ISpanMapper = {
	area: 'area',
	candle: 'candle',
	line: 'line',
	candlestick: 'candle'
};

const durationOptions = (t: any) => {
	const options = [
		{ label: `${t(localizationConstants.doNotMetter)}`, value: '' },
		{ label: `${t(localizationConstants.withoutDueDate)}`, value: 'withoutduedate' },
		{ label: `${t(localizationConstants.withoutFutures)}`, value: 'withoutfutures' },
		{ label: `${t(localizationConstants.futurePerptualContracsOnly)}`, value: 'futureperpetualcontracts' },
		{ label: `${t(localizationConstants.futureWithoutEndlessContract)}`, value: 'futureswithoutendlesscontracts' }
	];
	return options;
};

const defArray = ['atr', 'rsi', 'macd', 'sto'];

const radioButtonArray = (t: any) => {
	return [
		{
			name: t(localizationConstants.customMaterialStructure),
			radioBtns: [
				{
					name: 'material_status',
					label: t(localizationConstants.active),
					value: 'active'
				},
				{
					name: 'material_status',
					label: t(localizationConstants.activePlusOriginal),
					value: 'active+original'
				},
				{
					name: 'material_status',
					label: t(localizationConstants.inactive),
					value: 'inactive'
				},
				{
					name: 'material_status',
					label: t(localizationConstants.hideAll),
					value: 'hideall'
				}
			]
		},
		{
			name: t(localizationConstants.customNewsStructures),
			radioBtns: [
				{
					name: 'news_status',
					label: t(localizationConstants.active),
					value: 'active'
				},
				{
					name: 'news_status',
					label: t(localizationConstants.activePlusOriginal),
					value: 'active+original'
				},
				{
					name: 'news_status',
					label: t(localizationConstants.inactive),
					value: 'inactive'
				},
				{
					name: 'news_status',
					label: t(localizationConstants.hideAll),
					value: 'hideall'
				}
			]
		},
		{
			name: t(localizationConstants.customForecastStructure),
			radioBtns: [
				{
					name: 'forecast_status',
					label: t(localizationConstants.active),
					value: 'active'
				},
				{
					name: 'forecast_status',
					label: t(localizationConstants.activePlusOriginal),
					value: 'active+original'
				},
				{
					name: 'forecast_status',
					label: t(localizationConstants.inactive),
					value: 'inactive'
				},
				{
					name: 'forecast_status',
					label: t(localizationConstants.hideAll),
					value: 'hideall'
				}
			]
		}
	] as IRadioButtonArray[];
};

const downloadCurrenciesHeader = [
	{ header: 'waehrung', key: 'waehrung', width: 15 },
	{ header: 'wfaktor', key: 'wfaktor', width: 15 },
	{ header: 'name_de', key: 'name_de', width: 30 },
	{ header: 'name_en', key: 'name_en', width: 30 },
	{ header: 'sichtbar', key: 'sichtbar', width: 15 },
	{ header: 'sortidx', key: 'sortidx', width: 15 }
];

const downloadUnitsHeaders = [
	{ header: 'einheit', key: 'einheit', width: 30 },
	{ header: 'name', key: 'name', width: 30 },
	{ header: 'name_en', key: 'name_en', width: 15 },
	{ header: 'efaktor', key: 'efaktor', width: 30 },
	{ header: 'egruppe', key: 'egruppe', width: 15 },
	{ header: 'sichtbar', key: 'sichtbar', width: 15 }
];

const downloadCountriesAndRegions = [
	{ header: 'land', key: 'land', width: 15 },
	{ header: 'name_de', key: 'name_de', width: 15 },
	{ header: 'region', key: 'region', width: 15 },
	{ header: 'sichtbar', key: 'sichtbar', width: 15 },
	{ header: 'sortidx', key: 'sortidx', width: 15 }
];

const downloadNotations = [
	{ header: 'notierung', key: 'notierung', width: 15 },
	{ header: 'name', key: 'name', width: 15 },
	{ header: 'name_en', key: 'name_en', width: 15 },
	{ header: 'sortidx', key: 'sortidx', width: 15 }
];

const downloadTenadersCountriesHeaders = [
	{ header: 'Continent Eng', key: 'Continent Eng', width: 25 },
	{ header: 'Country Eng', key: 'Country Eng', width: 25 },
	{ header: 'Continent DE (Kontinent)', key: 'Continent DE (Kontinent)', width: 25 },
	{ header: 'Country DE (Land)', key: 'Country DE (Land)', width: 25 }
];

const downloadAdminMasterDataHeaders = [
	{ header: 'symbol', key: 'symbol', width: 15 },
	{ header: 'boerse', key: 'boerse', width: 15 },
	{ header: 'basis_symbol', key: 'basis_symbol', width: 15 },
	{ header: 'isin', key: 'isin', width: 15 },
	{ header: 'faelligkeit', key: 'faelligkeit', width: 15 },
	{ header: 'faelligkeit_f', key: 'faelligkeit_f', width: 15 },
	{ header: 'frei', key: 'frei', width: 15 },
	{ header: 'sichtbar', key: 'sichtbar', width: 15 },
	{ header: 'editierbar_', key: 'editierbar_', width: 15 },
	{ header: 'land', key: 'land', width: 15 },
	{ header: 'waehrung', key: 'waehrung', width: 15 },
	{ header: 'waehrung_ignore', key: 'waehrung_ignore', width: 15 },
	{ header: 'einheit', key: 'einheit', width: 15 },
	{ header: 'notierung', key: 'notierung', width: 15 },
	{ header: 'notierung_zusatz', key: 'notierung_zusatz', width: 180 },
	{ header: 'nkstellen', key: 'nkstellen', width: 15 },
	{ header: 'max_kursalter', key: 'max_kursalter', width: 15 },
	{ header: 'name_deu', key: 'name_deu', width: 90 },
	{ header: 'name_eng', key: 'name_eng', width: 90 },
	{ header: 'name_zusatz', key: 'name_zusatz', width: 90 },
	{ header: 'boersenname', key: 'boersenname', width: 90 },
	{ header: 'datenlieferant', key: 'datenlieferant', width: 15 },
	{ header: 'quelle', key: 'quelle', width: 45 },
	{ header: 'kunde', key: 'kunde', width: 15 },
	{ header: 'aid_kommentar_', key: 'aid_kommentar_', width: 60 },
	{ header: 'prozent_abweichung_erlaubt', key: 'prozent_abweichung_erlaubt', width: 45 },
	{ header: 'verantwortlich', key: 'verantwortlich', width: 15 },
	{ header: 'info', key: 'info', width: 15 }
];

const SUPPORTED_FORMATS = ['image/jpeg', 'image/png', 'image/jpg'];
const FILE_SIZE = 1 * 1024 * 1024; // 1 MB

const timeMapper: { [key: string]: string } = {
	'1d': 'Daily',
	'1w': 'Weekly',
	'1m': 'Monthly',
	'3m': '3 Months',
	'6m': '6 Months',
	'1y': '1 Year',
	all: 'All'
};

export {
	adminAdvancedSearchCsvHeaders,
	adminAuthBackpackNavbar,
	adminAuthUserNavbar,
	adminListEntriesHeader,
	adminMasterDataHeaders,
	adminMatrialForecastExcelHeaders,
	adminMatrialIntradayHeaders,
	API_CONFIG,
	apiMasterDataCsvHeaders,
	CHARTMAPPER,
	chartSettingColorPallet,
	checkBackpackGroupData,
	checkExpiredDate,
	checkMaterialData,
	COLOR_FIELDS,
	colorPallet,
	costModalTableHeader,
	courseDateRange,
	courseTableHeading,
	createOption,
	csvHeaders,
	customCsvHeaders,
	DATE_FORMAT_MAPPER,
	DATE_PLACEHOLDER_MAPPER,
	DateRangeMapper,
	defArray,
	durationOptions,
	englishLocale,
	ENVIRONMENT,
	FILE_SIZE,
	firstLevelBreadcrumbs,
	forecastBackpackMasterDataHeaders,
	germanLocale,
	getDifferenceDaysForOneYear,
	getFileUploadFolder,
	getUrl,
	groupUserListHeader,
	IMAGE_EXPORT_BUTTON_ARRAY,
	ImageLink,
	kebabMenu,
	materialUserCourseTableHeading,
	matfoxxJokesListHeaders,
	matfoxxOffersListHeaders,
	mouseEdgeAppearance,
	multiReactSelectStyles,
	newsBackpackMasterDataHeaders,
	newsListHeaders,
	pageOptions,
	passwordRegex,
	PER_PAGE,
	PROTOCOL_LIST_HEADER,
	radioButtonArray,
	reactSelectStyles,
	responsiveTableHEading,
	STROKESCOLORS,
	SUPPORTED_FORMATS,
	URL_REGEX,
	userListBackpackMasterDataHeaders,
	whiteSpceRegex,
	WINDOW_WIDTH,
	downloadCurrenciesHeader,
	downloadUnitsHeaders,
	downloadCountriesAndRegions,
	downloadNotations,
	downloadTenadersCountriesHeaders,
	downloadAdminMasterDataHeaders,
	statisticsDateRange,
	timeMapper
};
